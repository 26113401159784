import React from 'react'
import ReactDOM from 'react-dom'

import { CookiesProvider } from 'react-cookie'

import { store, persistor } from 'store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { BrowserRouter } from 'react-router-dom'

import { AppTheme } from 'Theme'
import App from 'App'

ReactDOM.render((
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AppTheme>
        <BrowserRouter>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </BrowserRouter>
      </AppTheme>
    </PersistGate>
  </Provider>
), document.getElementById('root'))
