import styled from 'styled-components'

export const Container = styled.div`

    h2 {
        margin-left: ${props => props.theme.spacing.xl};
    }

    @media only screen and (max-width: 568px) {
        h2 {
            margin-left: ${props => props.theme.spacing.md};
        }
    }

    .body-description {
        max-width: 90%;
        margin: ${props => props.theme.spacing.lg} auto 0;
    }

    .body-description-row{
        margin: auto;
        padding: 0 6.5%;
        padding-right: 0px;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    .label-description{
        padding: 0 0 2% 0;
    }

`