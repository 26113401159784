import React from 'react'

import { useLocation } from 'react-router-dom'

export const Page = (props) => {

    const { pathname } = useLocation()

    React.useEffect(() => {
        if (!pathname.includes('vagas'))
            window.scrollTo(0, 0)
    }, [pathname])

    return (
        <div style={{ flex: 1, marginTop: '78px', overflow: 'hidden' }}>{props.children}</div>
    )
}