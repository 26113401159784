import React from 'react'

import { Container } from './style'

export const PoliticaPrivacidade = () => {

    return (
        <Container>

            <div className={'wrapper'}>

                <div className={'main'}>

                    <div className={'title'}>Política de Privacidade</div>

                    <div className={'description'}>
                        A B2W tem um compromisso com a transparência, a privacidade e a segurança dos dados de seus usuários durante todo o processo de navegação no site. Para que entendam melhor quais informações coletamos e como as utilizamos, armazenamos ou excluímos, detalhamos a seguir nossa Política de Privacidade.
                        <br /><br />Os dados cadastrais dos usuários não são divulgados para terceiros, exceto quando necessários para o processo de recrutamento e seleção.
                        <br /><br />Para que a Política de Privacidade seja bem compreendida, é fundamental esclarecer alguns conceitos importantes:
                        <br /><br /><b>- Usuário </b>– toda pessoa física que navega ou se cadastra no site da B2W Carreiras;
                        <br /><br /><b>- Dados pessoais </b>– qualquer informação relacionada a uma pessoa que a identifique ou que, usada em combinação com outras informações tratadas, identifique um indivíduo. Ainda, qualquer informação por meio da qual seja possível identificar uma pessoa ou entrar em contato com ela.
                        <br /><br /><b>- Tratamento de dados pessoais </b>– considera-se tratamento de dado pessoal a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, comunicação, transferência, difusão ou extração de dados de pessoas físicas.
                        <br /><br /><b>- Titular de dados </b>– qualquer pessoa física que tenha seus dados pessoais tratados pela B2W;
                        <br /><br /><b>- Finalidade </b>– o que queremos alcançar com o tratamento de dados pessoais.
                        <br /><br /><b>- Necessidade </b>– o tratamento de dados pessoais deve se limitar ao mínimo necessário para o propósito almejado. Ou seja, deve ser pertinente, proporcional e não excessivo.
                        <br /><br /><b>- Consentimento </b>– autorização clara e objetiva que o titular dá para tratamento de seus dados pessoais com finalidade previamente estipulada. Após dar o consentimento, você pode revogá-lo a qualquer momento. A revogação não cancela os processamentos realizados previamente.
                        <br /><br /><b>- A B2W </b> é marca integrante do Universo Americanas, composto pelas empresas listadas abaixo:
                        <br /><br />B2W – Companhia Digital – 00.776.574/0006-60
                        <br />Lojas Americanas S.A. – 33.014.556/0001-96
                        <br />BIT Services Inovação e Tecnologia Ltda. – 03.789.968/0001-37
                        <br />Digital Finance Promotora Ltda. – 19.179.007/0001-40
                        <br />Direct Express Logística Integrada S.A. – 05.886.614/0001-36
                        <br />ST Importações Ltda. – 02.867.220/0001-42
                        <br />Submarino Finance Promotora de Crédito Ltda. – 07.897.468/0001-70
                        <br />Ame Digital Brasil Ltda. – 32.778.350/0001-70
                        <br />Ecologística Entregas Sustentáveis Ltda. – 22.236.389/0001-01
                        <br />Courrieros Transportes Ltda. – 29.386.039/0001-70
                        <br />Supernow Portal e Serviços de Internet Ltda. – 23.559.907/0001-90
                        <br />Ecolivery Courrieros Ltda – 16.890.506/0001-53


                        <br /><br />1.	A quem essa Política de Privacidade se aplica?
                        Esta Política aplica-se a todos os usuários da B2W Carreiras, que de alguma forma tenham os dados pessoais tratados por nós.

                        <br /><br />2.	Que tipo de informações pessoais coletamos e utilizamos?
                        A B2W coleta e armazena os seguintes tipos de informações:
                        <br /><br /><b>Informações que você nos fornece</b>: a B2W coleta informações do usuário quando você se cadastra para uma vaga ou quando preenche o formulário de interesse sobre determinada vaga que ainda não foi aberta. Dentre as informações que podem ser solicitadas estão: Nome completo, e-mail, estado de residência, CPF, telefone de contato.
                        <br /><br /><b>Informações coletadas automaticamente pela B2W</b>: coletamos e armazenamos determinadas informações sempre que o usuário interage conosco. Por exemplo, utilizamos cookies e obtemos informações quando seu navegador acessa o nosso site.
                        <br /><br /><b>Informações coletadas de outras fontes</b>: empresas provedoras de serviços de recrutamento e seleção.

                        <br /><br />3.	Por que solicitamos seus dados pessoais?
                        Os dados são necessários para:
                        <ul>
                            <li style={{ 'listStyle': 'disc' }}>incluir o usuário nos processos seletivos da Empresa;</li>
                            <li style={{ 'listStyle': 'disc' }}>traçar perfil do usuário;</li>
                            <li style={{ 'listStyle': 'disc' }}>otimizar sua interação conosco;</li>
                            <li style={{ 'listStyle': 'disc' }}>garantir a segurança do site e dos dados que processa.</li>
                            O fornecimento dos dados permite:
                            <li style={{ 'listStyle': 'disc' }}>cadastrar candidatos nas vagas em aberto na Empresa;</li>
                            <li style={{ 'listStyle': 'disc' }}>manter o usuário informado sobre as vagas de seu interesse;</li>
                            <li style={{ 'listStyle': 'disc' }}>melhorar sua experiência durante a navegação em nosso site.</li>
                        </ul>

                        <br />A B2W utiliza bases legais, que podem variar de acordo com a finalidade da coleta, para tratar os dados pessoais. O prazo de armazenamento pode mudar conforme a base legal aplicável a cada situação e a finalidade.

                        <br /><br />4.	Com quem compartilhamos seus dados?

                        As informações coletadas somente serão compartilhadas quando forem necessárias: (i) proteção em caso de conflito; (ii) mediante decisão judicial ou requisição de autoridade competente; (iii) com empresas provedoras de ferramentas de recrutamento e seleção.

                        <br /><br />5.	Transferência Internacional de dados

                        Como a internet é um ambiente global, determinados serviços oferecidos pela B2W podem demandar a transferência dos seus dados para outros países.
                        Nesses casos, os dados são tratados de acordo com a LGPD (Lei Geral de Proteção de Dados) e demais legislações de proteção. Tomamos medidas de segurança de acordo com nossas políticas e adotamos cláusulas padrão nos contratos com fornecedores e prestadores de serviço.
                        Ao navegar em nosso site, você concorda com o tratamento de suas informações pelas marcas integrantes do Universo Americanas, inclusive a transferência internacional de dados, quando necessário. Adotamos medidas para garantir que quaisquer informações coletadas sejam tratadas com segurança, conforme os padrões de proteção de dados e de acordo com esta Política de Privacidade.

                        <br /><br />6.	Por quanto tempo armazenamos informações pessoais?
                        Armazenamos as informações dos usuários de acordo com as normas de prescrição do Direito brasileiro.

                        <br /><br />7.	Quais são os direitos do titular de dados?
                        O titular dos dados pessoais possui o direito de obter da B2W, a qualquer momento, mediante requisição formal, informações referentes aos seus dados.
                        A B2W terá 15 dias para responder às solicitações dos titulares. Os pedidos serão analisados conforme previsto em legislação vigente e, por questões legais, algumas solicitações podem não ser atendidas.
                        Os titulares dos dados, segundo o texto da LGPD, podem exercer os seus direitos por meio de:
                        <ul>
                            <li style={{ 'listStyle': 'upper-roman' }}> - confirmação da existência de tratamento;</li>
                            <li style={{ 'listStyle': 'upper-roman' }}> - acesso aos dados;</li>
                            <li style={{ 'listStyle': 'upper-roman' }}> - correção de dados incompletos, inexatos ou desatualizados;</li>
                            <li style={{ 'listStyle': 'upper-roman' }}> - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</li>
                            <li style={{ 'listStyle': 'upper-roman' }}> - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
                            <li style={{ 'listStyle': 'upper-roman' }}> - eliminação dos dados pessoais tratados com o consentimento do titular;</li>
                            <li style={{ 'listStyle': 'upper-roman' }}> - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
                            <li style={{ 'listStyle': 'upper-roman' }}> - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
                            <li style={{ 'listStyle': 'upper-roman' }}> - revogação do consentimento.</li>
                        </ul>

                        <br /><br />8.	Como exercer os seus direitos?
                        Você pode exercer seus direitos entrando em contato pelo e-mail <a href={'mailto:falecomrh@b2wdigital.com'}>falecomrh@b2wdigital.com</a>.

                        <br /><br />9.	Cookies e tecnologias semelhantes
                        Cookies são pequenos arquivos de dados que são colocados no seu computador ou em outros dispositivos (como 'smartphones' ou 'tablets') enquanto você navega no site.
                        Utilizamos cookies, pixels e outras tecnologias (coletivamente, "cookies") para reconhecer seu navegador ou dispositivo, fornecer recursos e serviços essenciais e também para:

                        <br /><br />
                        <ul>
                            <li style={{ 'listStyle': 'disc' }}>realização de pesquisas e diagnósticos para melhorar o conteúdo, produtos e serviços;</li>
                            <li style={{ 'listStyle': 'disc' }}>impedir atividades fraudulentas;</li>
                            <li style={{ 'listStyle': 'disc' }}>melhorar a segurança.</li>
                        </ul>

                        <br />Se você bloquear ou rejeitar nossos cookies, algumas funcionalidades do site podem não funcionar corretamente.
                        Clique em 'Ajuda', nas configurações do seu navegador, para saber como impedi-lo de aceitar cookies e para ser notificado quando receber novos; para ver quando eles expiram e para desativá-los. Nos links abaixo você encontra mais detalhes sobre como desativar os cookies dos navegadores mais populares:
                        <br /><br />
                        <ul>
                            <li style={{ 'listStyle': 'disc' }}>Google Chrome</li>
                            <li style={{ 'listStyle': 'disc' }}>Mozilla Firefox</li>
                            <li style={{ 'listStyle': 'disc' }}>Safari</li>
                            <li style={{ 'listStyle': 'disc' }}>Internet Explorer</li>
                            <li style={{ 'listStyle': 'disc' }}>Microsoft Edge</li>
                            <li style={{ 'listStyle': 'disc' }}>Opera</li>
                        </ul>

                        <br /><br />10.	Como você pode manter suas informações pessoais seguras?
                        A B2W utiliza os melhores protocolos de segurança para preservar a privacidade dos dados dos usuários, mas também recomenda medidas de proteção individual.
                        Para manter a segurança e proteção dos seus dados pessoais, em nenhuma hipótese o seu login e senha devem ser compartilhados com terceiros. Além disso, ao acessar o seu cadastro, principalmente em computadores públicos, certifique-se de que você realizou o logout da sua conta para evitar que pessoas não autorizadas acessem e utilizem as informações sem o seu conhecimento.

                        <br /><br />11.	Legislação e foro
                        Esta política será regida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, especialmente a Lei nº 13.709/2018, independentemente das Leis de outros estados ou países, sendo competente o foro de domicílio do usuário para dirimir qualquer dúvida decorrente deste documento.

                        <br /><br />12.	Alterações nesta Política de Privacidade
                        Se fizermos quaisquer alterações nesta política ela será atualizada aqui neste site.

                        <br /><br />13.	Última atualização
                        Última atualização: 25 de fevereiro de 2020

                        <br /><br />

                    </div>

                </div>

            </div>

        </Container>
    )
}