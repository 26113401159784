import styled from 'styled-components'

export const Container = styled.div`

    margin: ${props => props.theme.spacing.xl} 0;

    .opportunities {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }
        
    @media only screen and (max-width: 1200px) {
        .opportunities {
            padding: 0 ${props => props.theme.spacing.lg};
        }
    }
    
    @media only screen and (max-width: 1133px) {
        
        .opportunities {
            flex-direction: column;
        }

        .opportunityCard {
            max-width: 100%;
        }

    }

    @media only screen and (max-width: 840px) {
        .opportunities {
            padding: 0 ${props => props.theme.spacing.lg};
            gap: 0;
        }
    }

`