import React from 'react'

import { useCookies } from 'react-cookie'

import { Container } from './style'

export const PrivacyPolicy = () => {

    const [cookies, setCookie] = useCookies(['privacy-policy'])

    const setPolicyCookie = () => {
        setCookie('privacy-policy', new Date(), { maxAge: 999999999 })
    }

    return (
        <>
            {cookies['privacy-policy'] ? null :
                <Container>
                    <div className={'message'}>Usamos cookies para permitir que o nosso site funcione corretamente e melhorar sua experiência no site.</div>
                    <div className={'button'} onClick={setPolicyCookie}>Aceito</div>
                </Container>
            }
        </>
    )
}