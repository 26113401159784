import React from 'react'

import { Switch, Route } from 'react-router-dom'

import {
    Home,
    Vagas,
    PoliticaPrivacidade,
    EstagioTech,
    ProgramaTrainee,
    EstagioLoja,
    EstagioCorporativo
} from 'pages'

export const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/vagas/:qSearch?" component={Vagas} />
            <Route exact path="/politica-privacidade" component={PoliticaPrivacidade} />
            <Route exact path="/estagio-tech/" component={EstagioTech} />
            <Route exact path="/programa-trainee/" component={ProgramaTrainee} />
            <Route exact path="/estagio-loja/" component={EstagioLoja} />
            <Route exact path="/estagio/" component={EstagioCorporativo} />
            <Route exact path="*" component={() => 404} />
        </Switch>
    )
}
