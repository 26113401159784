import styled from 'styled-components';

import logo from 'assets/images/logo.svg';
import triggerOpen from './assets/images/trigger-open.png';
import triggerClose from './assets/images/trigger-close.png';
import selo from './assets/images/selos_horizontal_a_1MO.svg';

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  color: ${(props) => props.theme.color.neutral100};
  background-color: ${(props) => props.theme.color.red900};

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${(props) => props.theme.spacing.md} 0;
  }

  @media only screen and (max-width: 1232px) {
    .header-container {
      padding: ${(props) => props.theme.spacing.md};
    }
  }

  .header-logoLink {
    display: contents;
  }

  .header-logo {
    width: 100%;
    height: 38px;
    background: url(${logo}) no-repeat left center;
    margin-right: ${(props) => props.theme.spacing.md};
  }

  @media only screen and (max-width: 438px) {
    .header-logo {
      background-size: contain;
    }
  }

  @media only screen and (max-width: 960px) {
    .header-menu {
      display: none;
    }
  }

  .header-menu ul {
    display: flex;
  }

  .header-menu ul li a {
    color: ${(props) => props.theme.color.neutral100};
    border-top: ${(props) => props.theme.line.lg} solid transparent;
    border-bottom-width: ${(props) => props.theme.line.lg};
    margin: 0 ${(props) => props.theme.spacing.md};
    transition: padding 0.3s, border 0.3s;
    padding: 0px ${(props) => props.theme.spacing.xs};
  }

  .header-menu ul li a:hover,
  .header-menu ul li a.--active {
    padding: 4px ${(props) => props.theme.spacing.xs};
    border-color: ${(props) => props.theme.color.neutral100};
  }

  .social {
    display: flex;
    align-items: center;
    margin-left: ${(props) => props.theme.spacing.lg};
  }

  @media only screen and (max-width: 960px) {
    .social {
      display: none;
    }
  }

  .social a {
    border-bottom: none;
  }

  .socialIcon {
    width: 24px;
    height: 24px;
    background-size: contain;
    margin-left: ${(props) => props.theme.spacing.lg};
    transition: all 0.3s;
  }

  .social .socialIcon:hover {
    filter: invert();
  }

  .social .socialIcon .a {
    stroke: none;
  }

  .socialIcon .spotify {
    width: 24px;
    width: 24px;
  }

  .mobile-menu {
    position: relative;
  }

  @media only screen and (min-width: 940px) {
    .mobile-menu {
      display: none;
    }
  }

  .mobile-menuTrigger {
    width: 45px;
    height: 45px;
    margin: 0 5px;
    transition: all 0.4s;
  }

  .mobile-menuTrigger.--isClose {
    background: url(${triggerOpen}) no-repeat center center;
  }

  .mobile-menuTrigger.--isOpen {
    transform: rotateZ(180deg);
    background: url(${triggerClose}) no-repeat center center;
  }

  .mobile-menuContent {
    z-index: 666;
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    top: 85px;
    right: 0px;
    overflow-y: auto;
    width: 80vw;
    height: calc(100vh - 78px);
    background-color: ${(props) => props.theme.color.neutral100};
    transition: transform 0.3s;
  }

  .mobile-menuContent.--isOpen {
    transform: translateX(0vw);
  }

  .mobile-menuContent.--isClose {
    transform: translateX(100vw);
  }

  .mobile-menuContent ul {
    padding: 0 ${(props) => props.theme.spacing.lg};
  }

  .mobile-menuContent ul li a {
    display: block;
    color: ${(props) => props.theme.color.neutral800};
    padding: ${(props) => props.theme.spacing.md} 0;
    border-color: ${(props) => props.theme.color.neutral200};
  }

  .mobile-menuContent ul li a.--active {
    color: ${(props) => props.theme.color.red900};
  }

  .mobile-menuContent .social {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 0;
    margin-bottom: ${(props) => props.theme.spacing.lg};
    padding: ${(props) => props.theme.spacing.xl} 0
      ${(props) => props.theme.spacing.md} 0;
  }

  .mobile-menuContent .socialIcon {
    margin: 0;
  }

  .mobile-menuContent .socialIcon:hover {
    filter: none;
  }

  .mobile-menuContent .socialIcon .a {
    fill: ${(props) => props.theme.color.red900};
    stroke: none;
  }

  .mobile-menuContent .socialIcon .d {
    fill: ${(props) => props.theme.color.red900};
    stroke: none;
  }

  .mobile-menuContent .selo {
    align-self: center;
    width: 100%;
    min-height: 20%;
    background: url(${selo}) top center no-repeat
      ${(props) => props.theme.color.neutral850};
    background-size: contain;
  }
  @media only screen and (max-width: 386px) {
    .mobile-menuContent .selo {
      min-height: 17%;
    }
  }
`;
