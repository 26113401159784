import styled from 'styled-components'

export const Container = styled.div`
    z-index: 999;
    position: fixed;
    top: auto;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    width: 100vw;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;    
    backdrop-filter: blur(12px);

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 2px solid white;
        padding: 10px 40px;
        margin: 10px;
        transition: all .3s;
    }

    .button:hover {
        background-color: white;
        color: black;
    }

    @media screen and (max-width: 460px) {
        flex-direction: column;
    }

`