import React from 'react'

import { Container } from './style'

import { VideoPlayer } from './components/VideoPlayer'
import { Purpose } from './components/Purpose'
import { Values } from './components/Values'
import { Principles } from './components/Principles'

export const About = (props) => {

    return (
        <Container {...props}>

            <div className={'wrapper'}>

                <h1>sobre a <b>americanas</b> s.a.</h1>

                <div className={'body-description'}>

                    <div className={'body-description-row label-description'}>
                        Somos uma multiplataforma de inovação tecnológica com atuação em: varejo, e-commerce, logística, fintech e publicidade, impulsionadas por um forte motor de inovação.
                    </div>

                    <div className={'body-description-row label-description'}>
                        Uma Companhia criada a partir da combinação de Lojas Americanas e B2W, dona das marcas mais queridas da internet. Somamos mais de 53 milhões de clientes ativos, mais de 44 mil associados e mais de 3.500 lojas em todo o país. Incrível, né?
                    </div>

                    <div className={'body-description-row label-description'}>
                        Nosso propósito é somar o que o mundo tem de bom para melhorar a vida das pessoas!
                    </div>
                    
                </div>

                <VideoPlayer />

                <Purpose id={'proposito'} />

                {/* <Values id={'valores'} /> */}

                <Principles id={'principios'} />

            </div>

        </Container>
    )
}