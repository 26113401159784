import React from 'react'

import { useSelector } from 'react-redux'

import { ThemeProvider, createGlobalStyle } from 'styled-components'

//HelveticaNeueLTStd-Md.otf'
import RobotoMd from 'assets/fonts/Roboto/Roboto-Bold.ttf'

//HelveticaNeueLTStd-Roman.otf'
import RobotoRo from 'assets/fonts/Roboto/Roboto-Medium.ttf'

//HelveticaNeueLTStd-Bd.otf'
import RobotoBd from 'assets/fonts/Roboto/Roboto-Bold.ttf'

//HelveticaNeueLTStd-Th.otf'
import RobotoTh from 'assets/fonts/Roboto/Roboto-Light.ttf'

export const AppTheme = (props) => {

    const currentTheme = useSelector(state => state.theme.currentTheme)

    const baseTheme = {
        spacing: {
            xxs: '3px',
            xs: '5px',
            sm: '10px',
            md: '20px',
            lg: '30px',
            xl: '50px',
        },
        font: {
            xs: '12px',
            sm: '14px',
            md: '16px',
            lg: '28px',
            xl: '38px',
        },
        icon: {
            sm: '16px',
            md: '22px',
            lg: '32px',
        },
        line: {
            xs: '1px',
            sm: '1.5px',
            md: '2px',
            lg: '4px',
            xl: '8px',
        },
        round: {
            sm: '3px',
            md: '6px',
            lg: '10px',
            xl: '100px',
        }
    }

    return (
        <ThemeProvider theme={{ ...baseTheme, ...Theme[currentTheme] }}>
            <>
                <GlobalStyle />
                {props.children}
            </>
        </ThemeProvider>
    )
}

export const Theme = {
    default: {
        color: {
            red400: '#ff0037',
            red500: '#ff0000',
            red550: '#bb0F33',
            red600: '#e60019',
            red700: '#e60000',
            red900: '#c40023',
            red800: '#f80032',
            red950: '#b71836',
            orange400: '#ff9800',
            orange500: '#ff8723',
            orange900: '#FF4100',

            neutral100: '#ffffff',
            neutral200: '#f0f0f0',
            neutral300: '#f5f5f5',
            neutral400: '#eeeeee',
            neutral500: '#e0e0e0',
            neutral600: '#bdbdbd',
            neutral700: '#9e9e9e',
            neutral800: '#707070',
            neutral850: '#555555',
            neutral900: '#212121',
            neutral950: '#000000',

        }
    }
}

export const GlobalStyle = createGlobalStyle`
    
    @font-face {
        font-family: 'Roboto-Bold';
        src: url(${RobotoMd}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Roboto-Medium';
        src: url(${RobotoRo}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Roboto-Black';
        src: url(${RobotoBd}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Roboto-Thin';
        src: url(${RobotoTh}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
        
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        list-style: none;
        outline: none;
        -webkit-text-size-adjust: 100%;
    }

    a {
        color: ${props => props.theme.color.red900};
        border-bottom: ${props => props.theme.line.sm} solid transparent;
        text-decoration: none;
        transition: border-color .3s ease-in-out;
    }

    a:hover {
        border-color: ${props => props.theme.color.red900};
    }

    b {
        font-family: 'Roboto-Black';
        font-weight: initial;
    }

    h1, h2 {
        align-self: flex-start;
        font-family: 'Roboto-Thin';
        font-weight: 500;
        font-size: ${props => props.theme.font.xl};
        line-height: 42px;
        color: ${props => props.theme.color.red900};
    }
    
    h2 {
        font-size: ${props => props.theme.font.lg};
    }

    @media only screen and (max-width: 1200px) {
        h1 {
            margin: 0 ${props => props.theme.spacing.md};
        }
    }

    body {
        font-family: 'Roboto', sans-serif;
        font-size: ${props => props.theme.font.md};
        line-height: 25px;
        text-size-adjust: 100%;
        color: ${props => props.theme.color.neutral800};
        background-color: ${props => props.theme.color.neutral100};
    }

    #root {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: ${props => props.theme.color.neutral400};
    }

    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.color.neutral700};
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.color.neutral800};
    }
 
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .MuiButtonBase-root.Mui-disabled {
        && {
            background-color: ${props => props.theme.color.neutral400};
            color: ${props => props.theme.color.neutral700};
        }
    }

    .MuiButton-label {
        text-transform: none;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        max-width: 1180px;
        margin: 0px auto;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }

    .invisible {
        display: none !important;
    }
`