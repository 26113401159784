import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import ThemeReducer from './Theme'

const reducers = combineReducers({
    theme: ThemeReducer,
})

const persistConfig = {
    key: 'app',
    whitelist: ['theme'],
    storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer)
const persistor = persistStore(store)

export { store, persistor }