import React from "react";

import { Button } from '@material-ui/core'

import { Container } from "./style";

import { Prerequisites } from "./components/Prerequisites";
import { CardTimeline } from "./components/CardTimeline";
import { Benefits } from "./components/Benefits";

import americanas from './assets/logo-americanas.svg'
import shoptime from './assets/logo-shoptime.svg'
import submarino from './assets/logo-submarino.svg'
import soubarato from './assets/logo-soubarato.svg'

export const ProgramaTrainee = (props) => {

    const brands = [
        { name: 'americanas', logo: americanas, description: 'americanas', link: 'https://www.americanas.com.br/' },
        { name: 'submarino', logo: submarino, description: 'submarino', link: 'https://www.submarino.com.br/' },
        { name: 'shoptime', logo: shoptime, description: 'shoptime', link: 'https://www.shoptime.com.br/' },
    ]

    const list_info_prog = [
        '12 meses de duração (100% remoto);',
        'Job Rotation em vááárias áreas;',
        'Muuuitos treinamentos e aprendizado;',
        'Participação em projetos inovadores;',
        'Contato próximo com a alta liderança.'
    ]

    return(
        <Container>
            
            <div className="banner">

                <div className="banner-slogan">

                    <div className="banner-title">

                        <div className="banner-slogan-title"></div>

                        <div className="banner-slogan-Sub-Title">
                            
                            <h2 className="inscricoes-aberta">inscrições encerradas</h2>
                            
                        </div>
                    </div>

                    <div className="banner-pessoas">
                        <div className="hashtag-info"></div>
                    </div>
                    
                </div>
            </div>

            <div className={'container-box'}>
                <div className={'wrapper'}>

                    <div className={'header-line-row'}>

                        <div className={'header-line-slogan'}>
                            <div className={'slogan'}>
                                <div className="slogan-text">
                                    <h1 className="text-white">vem crescer no</h1>
                                    <h1 className="text-white"><b>trainee americanas s.a.</b></h1> 
                                </div>
                                
                            </div>
                        </div>

                        <div className={'header-line-button invisible'}>
                            <Button className={'header-button'} size="large">inscreva-se</Button>
                        </div>
                       
                    </div>
                </div>
               
            </div>


            <div className={'body-description'}>

                <div className={'wrapper'}>
                                       
                    <div className={'body-description-content'}>

                        <div className={'body-description-col'}>

                            <h2 className={'body-description-label'}>
                                quem somos
                            </h2>

                            <div className={'body-description-row label-description'}>
                                Somos uma multiplataforma de inovação tecnológica com atuação em quatro frentes:
                                varejo (físico e digital), logística, fintech e publicidade.
                            </div>

                            <div className={'body-description-row label-description'}>
                                Nascemos da união de Lojas Americanas e B2W, dona das marcas mais queridas da internet.
                            </div>

                            <div className={'body-description-row label-description'}>
                                Somamos mais de 50 milhões de clientes ativos, mais de 40 mil colaboradores e
                                mais de 3.400 lojas em todo o país. Incrível, né?
                            </div>

                            <div className={'body-description-row label-description'}>
                                Nosso propósito é somar o que o mundo tem de bom para melhorar a vida das pessoas!

                            </div>

                            <div className={'body-description-row label-description'}>
                                Somos um time diverso, que soma experiências e pontos de vista diferentes,
                                mas que compartilha dos mesmos valores e objetivos.
                            </div>

                        </div>

                        <div className={'body-description-col'}>

                            <h2 className={'body-description-label'}>o programa</h2>

                            <div className={'body-description-row label-description'}>
                                O trainee americanas s.a. é para quem quer crescer e se desenvolver em um ambiente
                                dinâmico, inovador e em constante evolução, com muitos desafios.
                            </div>
                           
                            <div className={'body-description-row label-description'}>

                                <div className={'label-description-col'}>
                                    o programa conta com:
                                </div>

                                <div className="list-program">
                                    {list_info_prog.map((info, index) => (
                                        <ul key={index}>
                                            <li>{info}</li>
                                        </ul>
                                    ))}
                                </div>
                            </div>

                            <div className={'body-description-row label-description'}>
                                Se você é apaixonado por tecnologia, entregar resultados, trabalhar de forma colaborativa
                                e dinâmica, vem somar com a gente!
                                <span className="hashtag-color">
                                    <b> #osdiferentescrescemjuntos</b>
                                </span>
                               
                            </div>

                        </div>
                       
                    </div>

                </div>
           
            </div>

            
            <div className="bg-brands logo_brands">
                {brands.map((brand) => (
                    <div key={brand.name} className={`item ${brand.name}`}>
                        <a href={brand.link} target={'_blank'} rel={'noreferrer'}><img src={brand.logo} alt={brand.description} /></a>
                    </div>
                ))}
                
            </div>

            
            <div className="box-informations">
                    
                <div className="box-description-prerequisites">
                    <Prerequisites id={'requisitos'}/>
                </div>

                <div className="box-description-cards-timeline">
                    <CardTimeline id={'card-timeline'}/>
                </div>

            </div>
                       
            <div className={'box-benefits'}>
                
                <Benefits id={'Beneficios'}/>
            </div>
            

        </Container>
    )
}