import styled from 'styled-components'

export const Container = styled.div`

    align-items: center;
    background: #f80032;
   
    h2{
        color: ${props => props.theme.color.neutral100};
        margin-top: 7%;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
    }

    .benefits-container {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: wrap;

        justify-content: space-around;
        align-items: center;

        padding: 0 325px;
    }

    .benefits-box {
        display: flex;
        flex-direction: column;
    }

    .benefits-box-icon{
        margin: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 53px;
    }

    .benefits-header {
        width: 100%;
       
        display: flex;
        align-items: center;
       
        padding-bottom: ${props => props.theme.spacing.md};
    }

    .benefits-observation{
        width: 100%;

        user-select: none;
        display: flex;
        align-items: center;
       
        padding-bottom: ${props => props.theme.spacing.md};
        margin-top: ${props => props.theme.spacing.md};
    }

    .benefits-headerLabel {
        padding: 1px 20px 10px 10px;
        h1{
            color: ${props => props.theme.color.neutral100};
            font-size: 32px;
            margin: revert;
        }
        h2{
            text-align: left;
        }
    }

    .benefits-headerIcon {
        width: 16px;
        height: 16px;
        transition: all .6s;
    }

    .benefits-content {
        pointer-events: none;
        transition: max-height .6s, opacity .3s;
    }

    .body-description-row{
        margin: auto;
        padding: 0 30%;
       
        line-height: 24px;

        margin-bottom: 2%;
    }

    @media only screen and (min-width: 1440px) and (max-width: 2560px){

        .benefits-header {
            margin-left: 4%;
        }

        .benefits-headerLabel {
            width: 100%;
        }

        .benefits-container {
            max-width: 100%;
        }

        .benefits-observation {
            margin-left: 4%;
        }
    }

    @media only screen and (min-width: 2560px){

        .benefits-container {
            padding: 0 680px;
        }

        .benefits-headerLabel {
            padding: 1px 20px 10px 8px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 1440px){

        .benefits-container {
            padding: 0 120px;
        }

        .benefits-headerLabel {
            padding: 1px 20px 10px 15px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 1200px){

        .benefits-observation {
            margin-top: 20px;
        }
    }

    @media only screen and (max-width: 1024px){

        .benefits-container {
            padding: 0 10px;
        }

        .benefits-headerLabel {
            padding: 1px 20px 10px 40px;
        }

        .benefits-box {
            padding: 10px 10px 20px 0px;
        }   

        .benefits-observation {
            margin-top: 0;
        }

        .body-description-row{
            padding: 0 20%;
        }
    }

    @media only screen and (max-width: 950px){

        .benefits-box{
            padding: 30px 0px;
        }
    }

    @media screen and (max-width: 768px){
        
        .benefits-box{
            width: calc(30%);
            padding: 30px 0px;
            
        }

        .benefits-container{
            width: 100%;
            padding: 0 50px;
        }

        .benefits-header{
            padding-bottom: 0;
        }

        .benefits-headerLabel{
            margin: 0;
            padding: 1px 20px 0px 15px;
        }
    }

    @media screen and (max-width: 540px){
        
        .benefits-box{
            padding: 0px 0px 70px 0px;
            min-width: calc(50%);
        }

        .benefits-observation {
            margin-top: 35px;
        }

        .benefits-headerLabel{
            margin: 0;
            padding: 1px 20px 20px 15px;
        }
    }

    @media screen and (max-width: 425px){
        
        
        .benefits-container{
            padding: 0 10px;
        }

        .benefits-box{
            padding: 0px 0px 70px 0px;
            min-width: calc(50%);
        }

        .benefits-observation {
            margin-top: 20px;
        }
    }

    @media screen and (max-width: 320px){
        .benefits-box{
            padding: 0px 70px 70px 0px;
        }
    }

`

