import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: -webkit-fill-available;
   
    h1{
        color: ${props => props.theme.color.neutral100};
        font-size: 35px;
        margin: revert;
        margin-top: 7%;
    }

    .prerequisites-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .prerequisites-box {
        padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.sm};
    }

    .prerequisites-header {
        user-select: none;
        display: flex;
        align-items: center;
    }

    .prerequisites-headerLabel {
        h1{
            font-size: 32px;
            color: ${props => props.theme.color.red900};
            line-height: 42px;
            margin-top: 30px;
        }
    }

    .prerequisites-headerIcon {
        width: 16px;
        height: 16px;
        transition: all .6s;
    }

    .prerequisites-content {
        pointer-events: none;
        
        transition: max-height .6s, opacity .3s;
        max-height: 888px;
    }

    .prerequisites-content ul {
        font-size: 18px;
        line-height: 27px;
    }
   
    .prerequisites-content ul li {
        display: flex;
        align-items: flex-start;
        margin-top: ${props => props.theme.spacing.md};
    }
   
    .prerequisites-content ul li::before {
        content: "";
        min-width: 4px;
        min-height: 4px;
        margin-right: ${props => props.theme.spacing.md};
        margin-top: ${props => props.theme.spacing.xs};
        border: 2px solid #ff9800;

        border-radius: ${props => props.theme.round.sm};
    }

    @media only screen and (max-width: 768px) {
        
        .prerequisites-box {
            width: 100%;
            margin: 50px 10px;
            padding: 0;
        }

        .prerequisites-content{
            margin-top: 10px;
        }
    }

`

