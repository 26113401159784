import React from 'react'

import { Container } from './style'

export const TrailCards = (props) => {

    const [boxState, setBoxState] = React.useState({
        cards: false,
    })

    const handleHeaderClick = (boxName) => {
        setBoxState({
            [boxName]: !boxState[boxName]
        })
    }

    return (
        <Container {...props}>

            <div className={'trailCards-container'}>

                {props.value && props.value.map((trailCard) => (

                    <div key={trailCard.name} className={`trailCard-box ${boxState[trailCard.name] ? '--isOpen' : '--isClose'}`}>

                        <div className={'trailCard-header'} onClick={handleHeaderClick.bind(this, trailCard.name)}>
                            <div className={'trailCard-headerLabel'}>{trailCard.name}</div>
                            <div className={'trailCard-headerIcon'}></div>
                        </div>

                        <div className={'trailCard-content'}>
                            
                            {trailCard.subtitle}
                            
                            <ul>
                                {trailCard.items.map((item, key) => (
                                    <li key={key}>{item}</li>
                                ))}
                            </ul>
                                
                        </div>

                    </div>

                ))}

            </div>

        </Container>
    )
}