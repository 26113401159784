import styled from 'styled-components'

import arrow from 'assets/images/arrow.png'

export const Container = styled.div`
    
    position: relative;
    padding: ${props => props.theme.spacing.xl} 0px;
    
    .button {
        position: absolute;
        z-index: 2;
        top: 10px;
        left: 0;
        cursor: pointer;
        width: 25px;
        height: 42px;
        background: url(${arrow}) no-repeat center center;
        transition: all .3s;
    }

    .button:hover {
        filter: brightness(.8);
    }

    .button.prev {
        left: 0px;
        transform: rotateZ(180deg)
    }
    .button.next {
        left: auto;
        right: 0px;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
    }

    .item a {
        border: none;
    }

    .item img {
        width: 200px;
        height: 70px;
    }

    .vem img {
        width: 200px;
        height: 35px;
    }

    @media screen and (max-width: 667px) {

        .button.prev {
            left: 30px;
        }
    
        .button.next {
            right: 30px;
        }

    }    

`