import React from 'react'
import { useHistory } from 'react-router-dom'

import SearchIcon from '@material-ui/icons/Search'
import { Button } from '@material-ui/core'

import { Container } from './style'

export const Search = React.forwardRef((props, ref) => {

    const history = useHistory()

    const [value, setValue] = React.useState(props.defaultValue)

    React.useEffect(() => {
        setValue(props.defaultValue)
    }, [props.defaultValue])

    const handleChange = (event) => {

        setValue(event.target.value)

        if (props.onChange)
            props.onChange(event.target.value)

        if (event.target.value.length === 0)
            if (props.onSearch)
                props.onSearch('')

    }

    const handleClick = () => {
        if (props.onSearch)
            props.onSearch(value)

        redirectToSearchPage()
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {

            if (props.onSearch)
                props.onSearch(value)

            redirectToSearchPage()
        }
    }

    const redirectToSearchPage = () => {
        history.replace(`/vagas/${value ? value : ''}`)
    }

    return (
        <Container ref={ref} className={'search-component'}>
            <div className={'container'}>
                <div className={'label'}>pesquisar vagas</div>
                <div className={'input'}>

                    <input
                        type={'text'}
                        defaultValue={props.defaultValue}
                        placeholder={'desenvolvedor, ux, designer...'}
                        onKeyUp={handleKeyUp}
                        onChange={handleChange} />

                    <Button onClick={handleClick}><SearchIcon /></Button>

                </div>
            </div>
        </Container>
    )
})