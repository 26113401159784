import React from 'react'

import { Container } from './style'

import video from './assets/video/americanas(Libras).mp4'
import poster from './assets/images/poster.jpg'

export const VideoPlayer = () => {

    const videoRef = React.createRef()

    const [isPlaying, setIsPlaying] = React.useState(false)

    const handleVideoClick = () => {
        videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
        setIsPlaying(!videoRef.current.paused)
    }

    const handleVideoEnded = () => setIsPlaying(false)

    return (
        <Container>

            <div className={'shape1'}></div>
            <div className={'shape2'}></div>
            <div className={'shape3'}></div>
            <div className={'shape4'}></div>

            <div className={`video-container ${isPlaying ? '--isPlaying' : '--isPaused'}`}>
                <video ref={videoRef} poster={poster} onClick={handleVideoClick} onEnded={handleVideoEnded}>
                    <source src={video} type="video/mp4" />
                </video>
            </div>

        </Container>
    )
}