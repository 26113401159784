import styled from 'styled-components'

export const Container = styled.div`

    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        color: ${props => props.theme.color.red900};
        font-size: 28px;
        font-weight: bold;
        margin: 50px 0px;
    }

    .description {
        padding: 0 30px;
    }

`