import React from 'react'

import { Container } from './style'

export const OpportunityCard = (props) => {

    return (
        <Container className={'opportunityCard'} {...props}>

            <div className={'opportunityCard-header'}>
                {props.open && <div className={'opportunityCard-headerTag'}>inscrições encerradas!</div>}
                <div className={'opportunityCard-headerTitle'}><span>{props.title}</span><span>{props.subtitle}</span></div>
            </div>

            <div className={'opportunityCard-content'}>

                <div className={'opportunityCard-description'}>{props.description}</div>

                <ul>
                    {props.links && props.links.map((link, key) => (
                        <a href={link.link} target={'_blank'} rel={'noreferrer'} key={key}><li>{link.label}</li></a>
                    ))}
                </ul>

            </div>

            {props.seeMore && <div className={'opportunityCard-seeMore'}><a href={props.seeMore} rel={'noreferrer'}>ver todas as vagas</a></div>}

        </Container>
    )
}

OpportunityCard.defaultProps = {
    title: 'programas para'
}