import React from 'react'

import { Button } from '@material-ui/core'

import { Container } from './style'

export const CardTimeline = (props) => {

    const [boxState, setBoxState] = React.useState({
        inscricao: false,
        testes: false,
        desafio: false,
        game: false,
        day: false,
        painel: false
    })

    const cards = [
        {
            name: 'Inscrição',
            content: `Nossa inscrição é feita pela Gupy. Para que possamos conhecer você melhor, pedimos que preencha um simples cadastro.`
        },
        {
            name: 'Testes Gamificados',
            content: `Os testes são gamificados, assim você avalia seus conhecimentos e se diverte ao mesmo tempo!`
        },
        {
            name: 'Desafio Decode',
            content: `O desafio será totalmente interativo e em grupo, onde você vai desvendar diversos enigmas da americanas s.a. em um jogo online. Vai ser incrível!`
        },
        {
            name: 'Entrevista Gente & Gestão',
            content: `Nessa fase, teremos entrevista com o time de Gente e Gestão. 
            Será uma experiência divertida em um ambiente descontraído.`
        },
        {
            name: 'Day Challenge',
            content: `Participar do Day Challenge com a Liderança significa uma imersão incrível no nosso negócio! Teremos cases práticos, que contarão com a participação dos nossos executivos.`
        },
        {
            name: 'Painel com a Diretoria',
            content: `O grande dia! Nessa última etapa você conhecerá e terá a chance de se apresentar
             para a Diretoria e Heads da companhia.`
        },
    ]

    const handleHeaderClick = (boxName) => {
        setBoxState({
            inscricao: false,
            testes: false,
            desafio: false,
            game: false,
            day: false,
            painel: false,
            [boxName]: !boxState[boxName]
        })
    }

    return (
        <Container {...props}>

            <div className='header-card'>
                <div className='header-title-card'>
                    <h2> processo seletivo </h2>
                </div>
            </div>
            
            <div className={'principles-container'}>

                {cards && cards.map((card) => (
                 
                    <div key={card.name} className='card'>

                        <p className='contador'></p>

                        <div key={card.name} className={`principle-box ${boxState[card.name] ? '--isOpen' : '--isClose'}`}>

                            <div className={'principle-header'} onClick={handleHeaderClick.bind(this, card.name)}>
                                <h5 className={'principle-headerLabel'}>{card.name}</h5>
                                <div className={'principle-headerIcon'}></div>
                            </div>

                            <div className={'principle-content'}>
                                {card.content}
                            </div>

                        </div>
                            
                    </div>
                ))}

                <div className='button-box'>
                    <div className={'header-line-button without-button'}>
                        <Button className={'header-button'} size="large">inscreva-se</Button>
                    </div>
                </div>

            </div>

        </Container>
    )
}