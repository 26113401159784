import styled from 'styled-components'

import mais from './assets/images/mais.svg'
import menos from './assets/images/menos.svg'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    
    .trailCards-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: ${props => props.theme.spacing.md} 0;
    }
    
    .trailCard-box {
        padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.md};
        margin-bottom: ${props => props.theme.spacing.md};
        border-radius: ${props => props.theme.round.md};
        border: ${props => props.theme.line.sm} solid ${props => props.theme.color.orange500};
        width: 70%;
    }

    @media only screen and (max-width: 840px) {
        .trailCard-box {
            width: 90%;
        }
    }

    .trailCard-header {
        user-select: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-bottom: ${props => props.theme.line.sm} solid ${props => props.theme.color.neutral500};
        padding-bottom: ${props => props.theme.spacing.md};
    }

    .trailCard-box.--isClose .trailCard-header {
        margin: 0;
        padding: 0;
        border: none;
        margin-bottom: 10px;
    }

    .trailCard-headerLabel {
        flex: 1;
        font-size: 18px;
        color: ${props => props.theme.color.red900};
        font-weight: 300;
        font-size: 18px;
        line-height: 20px;
    }

    .trailCard-headerIcon {
        width: 16px;
        height: 16px;
        transition: all .6s;
    }
    .trailCard-box.--isOpen .trailCard-headerIcon {
        background: url(${menos}) center center no-repeat;
    }
    .trailCard-box.--isClose .trailCard-headerIcon {
        background: url(${mais}) center center no-repeat;
        transform: rotateZ(180deg);
    }

    .trailCard-content {
        pointer-events: none;
        margin-top: ${props => props.theme.spacing.md};
        transition: max-height .6s, opacity .3s;
        max-height: 888px;
    }
    .trailCard-box.--isClose .trailCard-content {
        max-height: 0px;
        margin-top: -20px;
        opacity: 0;
    }
    
    .trailCard-content ul li {
        display: flex;
        align-items: flex-start;
        margin-top: ${props => props.theme.spacing.md};
    }
    
    .trailCard-content ul li::before {
        content: "";
        min-width: 4px;
        min-height: 4px;
        margin-right: ${props => props.theme.spacing.md};
        margin-top: ${props => props.theme.spacing.xs};
        border: ${props => props.theme.line.md} solid ${props => props.theme.color.orange500};
        border-radius: ${props => props.theme.round.sm};
    }

`