import styled from 'styled-components'

import background from './assets/background.svg'
import slogan from './assets/slogan.svg'

export const Container = styled.div`

    .banner {
        background: url(${background});
    }
    
    .banner-slogan {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 260px;
    }
    
    .banner-sloganTitle {
        background: url(${slogan}) no-repeat center center;
        background-size: contain;
        width: 360px;
        height: 180px;
    }

    @media screen and (max-width: 415px) {
        .banner-sloganTitle {
            width: 260px;
        }
    }

    .banner span {
        display: block;
        font-weight: bold;
        margin-left: 20px;
        max-width: 280px;
    }

    .job {
        margin-top: ${props => props.theme.spacing.lg};
    }
    
    .job-controls {
        display: flex;
        justify-content: space-between;
        margin-top: ${props => props.theme.spacing.lg};
    }
    
    .job-controls.fixed {
        z-index: 666;
        position: fixed;
        top: 48px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        max-height: 0px;
        opacity: 0;
        transition: opacity .3s;
        overflow: hidden;
        backdrop-filter: blur(10px);
    }
    
    .job-controls.fixed .wrapper {
        flex-direction: row;
    }

    .job-controls.fixed.--isVisible {
        overflow: initial;
        max-height: 666px;
        opacity: 1;
        display: flex;
    }

    @media screen and (max-width: 1234px) {
        .job-controls.fixed.--isVisible {
            display: none;
        }
    }

    @media screen and (max-width: 1234px) {
        .job-controls {
            padding: 0 ${props => props.theme.spacing.md};
        }
    }

    @media screen and (max-width: 1100px) {
        .job-controls {
            flex-direction: column;
            justify-content: center;
        }
    }

    .input-container {
        min-width: 260px;
        margin-bottom: ${props => props.theme.spacing.lg};
    }
    
    .job-controls.fixed .input-container {
        margin-top: ${props => props.theme.spacing.md};
        margin-right: 60px;
    }
    
    @media screen and (max-width: 1190px) {
        .job-controls.fixed .input-container {
            margin-right: 0px;
            margin-bottom: 0px;
        }
    }

    .job-mobileSearch {
        z-index: 2;
        position: fixed;
        display: none;
        align-items: center;
        justify-content: center;
        color: ${props => props.theme.color.neutral100};
        bottom: 80px;
        right: 20px;
        width: 64px;
        height: 64px;
        border-radius: ${props => props.theme.round.xl};
        background-color: ${props => props.theme.color.red400};
    }

    .job-mobileSearch.--isVisible {
        display: flex;
    }

    @media screen and (min-width: 1234px) {
        .job-mobileSearch.--isVisible {
            display: none;
        }
    }

    @media screen and (max-width: 415px) {
        .input-container {
            width: 100%;
        }
    }

    .input-container .search-component {
        padding: 0;
    }
    
    .input-container .search-component .container {
        width: 100%;
        z-index: 1;
    }

    .input-container .search-component .label {
        display: none;
    }
    
    .input-container .search-component input {
        border: ${props => props.theme.line.sm} solid ${props => props.theme.color.orange500};
    }
    
    @media screen and (max-width: 1100px) {
        .input-container .search-component input {
            min-width: auto;
        }
    }

    .input-label{
        color: ${props => props.theme.color.red900};
        margin-bottom: ${props => props.theme.spacing.xs};
    }
    
    .job-result{
        display: flex;
        flex-direction: column;
        margin-bottom: ${props => props.theme.spacing.xl};
    }
    
    .result-title{
        margin: ${props => props.theme.spacing.md} 0px;
    }

    @media screen and (max-width: 1234px) {
        .result-title{
            padding: 0 ${props => props.theme.spacing.md};
        }
    }

    .result-title span {
        font-weight: bold;
    }
    
    .result-title span:nth-child(1) {
        font-size: ${props => props.theme.font.lg};
    }

    .result-body {
        display: flex;
        flex-direction: column;
    }

    .result-body a {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        text-transform: inherit;
        border: none;
    }
    
    .result-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-height: 60px;
        border-bottom: ${props => props.theme.line.xs} solid ${props => props.theme.color.neutral500};
        padding: ${props => props.theme.spacing.md} 0px;
        transition: all .3s;
    }
    
    @media screen and (max-width: 1234px) {
        
        .result-item {
            flex-direction: column;
            align-items: flex-start;
            justify-items: flex-start;
            max-height: initial;
            padding: ${props => props.theme.spacing.md};
        }

    }

    .result-item:hover{
        background-color: ${props => props.theme.color.neutral200};
    }
    
    .result-itemPosition{
        flex: 1;
        color: ${props => props.theme.color.red900};
        font-weight: bold;
        min-width: 50%;
        transition: text-indent .3s;
    }

    .result-body a:hover {
        & .result-itemPosition {
            text-indent: ${props => props.theme.spacing.md};
        }
    }

    .result-itemLocation {
        flex: 1;
        font-weight: bold;
        min-width: 40%;
    }

    .result-itemDepartment {
        flex: 1;
    }

    .job-result button {
        width: max-content;
        color: ${props => props.theme.color.neutral100};
        background-color: ${props => props.theme.color.orange500};
        border-radius: ${props => props.theme.round.md};
        margin-top: ${props => props.theme.spacing.xl};
    }
    
    @media screen and (max-width: 1190px) {
        .job-result button {
            margin-left: ${props => props.theme.spacing.md};
        }
    }

`