import styled from 'styled-components'

export const Container = styled.div`

    align-items: center;
    
    h2{
        font-family: 'Roboto', sans-serif;
        color: ${props => props.theme.color.neutral100};
        margin-top: 7%;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
    }

    .benefits-container {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: wrap;
    }

    .benefits-list {
        display: flex;
        flex-wrap: wrap;
    }

    .benefits-box {
        padding: 10px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        white-space: break-spaces;
        width: calc(20%);
    }


    .benefits-box-icon{
        margin: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 53px;
        margin-bottom: 30px;
    }

    .benefits-header {
        user-select: none;
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        padding-bottom: ${props => props.theme.spacing.md};
        margin-top: 60px;
    }

    .benefits-observation{
        user-select: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-bottom: ${props => props.theme.spacing.md};
    }

    .benefits-list {
        width: -webkit-fill-available;
    }

    .benefits-headerLabel {
        padding:20px;
        h1{
            color: ${props => props.theme.color.neutral100};
            font-size: 32px;
            margin: revert;
        }
        h2{
            text-align: left;
        }
    }

    .benefits-headerIcon {
        width: 16px;
        height: 16px;
        transition: all .6s;
    }

    .benefits-content {
        pointer-events: none;
        transition: max-height .6s, opacity .3s;
        max-height: 888px;
    }

    .benefits-content ul {
        font-size: 18px;
        line-height: 27px;
    }
    
    .benefits-content ul li {
        display: flex;
        align-items: flex-start;
        margin-top: ${props => props.theme.spacing.md};
    }
    
    .benefits-content ul li::before {
        content: "";
        min-width: 4px;
        min-height: 4px;
        margin-right: ${props => props.theme.spacing.md};
        margin-top: ${props => props.theme.spacing.xs};
        border: 2px solid #BB0F33;

        border-radius: ${props => props.theme.round.sm};
    }

    @media only screen and (min-width: 1440px) and (max-width: 2560px){

        .benefits-container, .benefits-header, .benefits-headerLabel {
            max-width: 1180px;
        }

    }

    @media screen and (max-width: 768px){
        .benefits-box{
            min-width: calc(50%);
        }

        .benefits-container{
            width: 100%;
            margin: 50px 0;
        }

        .benefits-headerLabe{
            margin: 0;
            padding: 1px 20px 20px 15px;
        }
        
    }

`