import React from 'react';

import { Container } from './style';

export const DefaultBanner = () => {
  return (
    <Container>
      <div className={'background'}></div>

      <div className={'wrapper'}>
        <div className={'slogan'}>
          <h1>
            <b> é tempo de somar! </b>
          </h1>
        </div>
        <div className={'selo'}></div>
        <div className={'americanassa'}></div>
      </div>
    </Container>
  );
};
