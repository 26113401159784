import styled from 'styled-components'

export const Container = styled.div`

    .select__control {
        font-size: ${props => props.theme.font.sm};
        background-color: ${props => props.theme.color.neutral100};
        border-radius: ${props => props.theme.round.md};
        padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sx};
        box-shadow: none;
        border: ${props => props.theme.line.sm} solid ${props => props.theme.color.orange500};
    }
    
    .select__control:hover, .select__control--is-focused:hover {
        border: ${props => props.theme.line.sm} solid ${props => props.theme.color.orange500};
    }

    .select__value-container {
    }
    
    .select__control input {
    }
    
    .select__input {
        
    }

    .select__placeholder {
    }

    .select__indicator-separator {
        background-color: transparent;
    }
    
    .select__indicators svg {
        transition: all .2s;
        color: ${props => props.theme.color.red900};
    }
    
    .select__control--menu-is-open .select__indicators svg {
        transform: rotateZ(-180deg);
        color: ${props => props.theme.color.red900};
    }

    .select__single-value {
    }

    .select__menu {
    }

    .select__option {
        cursor: pointer;
        text-indent: 5px;
        white-space: nowrap;
        color: ${props => props.theme.color.neutral800};
        border-bottom: 1px solid ${props => props.theme.color.neutral500};
    }
    
    .select__option:last-of-type {
        border: none;
    }
    
    .select__option:hover, .select__option--is-focused, .select__option--is-selected {
        background-color: ${props => props.theme.color.neutral400};
    }
        
    &.invalid .select__control {
    }

`