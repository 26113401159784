import React from 'react'

import { Container } from './style'

export const GeneralInformation = (props) => {

    const generalInformation = [
        {
            title: 'o que você vai encontrar por aqui:',
            titlebold: ' ',
            items: [
                'Muito conhecimento',
                'Americanas Educa - Universidade Corporativa',
                'Academia de Dados',
                'Empresa com selo Sim à igualdade Racial - IDBR',
                'Várias iniciativas sustentáveis e sociais', 
                'Selo Great Place to Work'
            ],
        }
    ]

    return (
        <Container {...props}>
                {generalInformation && generalInformation.map((generalInformation) => (

                    <div key={generalInformation.title} className={'generalInformation-container'}>

                        <div className={'generalInformation-box'}>

                            <div className={'generalInformation-header'}>
                                <div className={'generalInformation-headerLabel'}> 
                                    <h1>{generalInformation.title}<b>{generalInformation.titlebold}</b></h1> 
                                </div>
                            </div>

                            <div className={'generalInformation-content'}>
                                <ul>
                                    {generalInformation.items.map((item, key) => (
                                        <li key={key}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                           
                        </div>

                    </div>

                ))}
        </Container>
    )
}

