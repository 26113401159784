import React from "react";

import { Button } from '@material-ui/core'

import { Container } from "./style";

import { GeneralInformation } from "./components/GeneralInformation"
import { Prerequisites } from "./components/Prerequisites"
import { CardTimeline } from "./components/CardTimeline"
import { Benefits } from "./components/Benefits"

import americanas from './assets/logo-americanas.svg'
import shoptime from './assets/logo-shoptime.svg'
import submarino from './assets/logo-submarino.svg'

export const EstagioLoja = (...props) => {

    const brands = [
        { name: 'americanas', logo: americanas, description: 'americanas', link: 'https://www.americanas.com.br/' },
        { name: 'shoptime', logo: shoptime, description: 'shoptime', link: 'https://www.shoptime.com.br/' },
        { name: 'submarino', logo: submarino, description: 'submarino', link: 'https://www.submarino.com.br/' }
    ]

    const list_info_prog = [
        'Até  8 meses de duração (100% presencial);', 
        'Job rotation em todas áreas da Loja;', 
        'Muitos treinamentos e mão na massa;', 
        'Participação em projetos inovadores;', 
        'Contato próximo com a alta liderança.'
    ]

    function toscroll(){
        const divElement = document.getElementById('#informations')
        divElement.scrollIntoView({ block: "center", behavior: "smooth"})
    }

    return(
        <Container>
            
            <div className="banner" role={'img'} alt={'Fundo amarelo e no centro imagem de uma biblioteca (imagem em preto e branco).'}>

                <div className="banner-slogan">
                    
                    <div className="banner-title">
                        
                        <div className="wrapper banner-slogan-title"></div>

                    </div>

                    <div className="banner-title-content">                   
                        
                        <div className="rectangle-mulher" role={'img'} alt={'Mulher de camisa preta de bolinhas brancas, cabelo preso, com óculos de armação arredondada e sorrindo (imagem preto e branco).'}></div>

                        <div className="rectangle-homem" role={'img'} alt={'Homem de camisa branca sorrindo e olhando para frente (imagem preto e branco).'}></div>                    
                    
                    </div>

                    <div className="banner-slogan-footer"></div>
                    
                    <div className="hashtag-info" role={'img'} alt={'#Somar Diferente Esperiências'}></div>

                </div>

            </div>

            <div className={'container-box'}>

                <div className={'wrapper'}>

                    <div className={'header-line-row'}>

                        <div className={'header-line-slogan'}>
                            <div className={'slogan'}>
                                <div className="slogan-text">
                                    <h1 className="text-white"> Vem somar no</h1>
                                    <h1 className="text-white"><b>estágio em loja americanas s.a.</b></h1> 
                                </div>
                            </div>

                        </div>

                        <div className={'header-line-button'}>
                            <Button onClick={toscroll} className={'header-button invisible'} size="large">inscreva-se</Button>
                        </div>

                    </div>

                </div>

            </div>

            <div className={'body-description'}>

                <div className={'wrapper'}>

                    <h1 className={'body-decription-raw'}>
                        O programa terá duração de <b className={'text-bold'}>até 8 meses</b> e, ao final,
                        você pode <b className={'text-bold'}>se tornar um Gerente de Loja</b>! 
                    </h1> 

                    <div className={'body-description-content'}>

                        <div className={'body-description-col'}>

                            <h1 className={'body-description-label'}>
                                quem somos
                            </h1>

                            <div className={'body-description-row label-description'}>
                                Somos uma multiplataforma de inovação tecnológica com atuação em: varejo, e-commerce, logística,
                                fintech e publicidade, impulsionadas por um forte motor de inovação.
                            </div>

                            <div className={'body-description-row label-description'}>
                                Nascemos da união de Lojas Americanas e B2W, dona das marcas mais queridas da internet.                            
                            </div>

                            <div className={'body-description-row label-description'}>
                                Somamos mais 51 milhões de clientes ativos, mais de 44 mil associados, mais de 3.500 lojas
                                em todo o país e R$ 55,3 bilhões em vendas no último ano. Incrível, né?                             
                            </div>

                            <div className={'body-description-row label-description'}>
                                Nosso propósito é somar o que o mundo tem de bom para melhorar a vida das pessoas.
                            </div>

                            <div className={'body-description-row label-description'}>
                                Somos um time diverso, que soma experiências e pontos de vista diferentes, mas que compartilha dos mesmos valores e objetivos.
                            </div>

                            <div className="bg-brands logo_brands">
                                {brands.map((brand) => (
                                    <div key={brand.name} className={`item ${brand.name}`}>
                                        <a href={brand.link} target={'_blank'} rel={'noreferrer'}><img src={brand.logo} alt={brand.description} /></a>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <div className={'body-description-col'}>

                            <h1 className={'body-description-label'}>o programa</h1>

                            <div className={'body-description-row label-description'}>

                                O Estágio em Loja é para quem quer somar diferentes experiências na sua carreira! Em <b className={'text-bold'}>até 8 meses</b>,
                                você vai aprender na prática tudo o que precisa para se tornar um <b className={'text-bold'}>Gerente de Loja</b>.
                            
                            </div>
                           
                            <div className={'body-description-row label-description'}>

                                <div className={'label-description-col'}>
                                    o programa conta com:
                                </div>

                                <div className="list-program">
                                    {list_info_prog.map((info, index) => (
                                        <ul key={index}>
                                            <li>{info}</li>
                                        </ul>
                                    ))}
                                </div>
                            </div>

                            <div className={'body-description-row label-description'}>

                                Nossas lideranças e o time de Gente acompanham os jovens durante todo o programa! Além de passar por diversos
                                treinamentos online sobre cada processo que será vivenciado na prática!
        
                            </div>

                            <div className={'body-description-row label-description'}>

                                <span className="hashtag-color">
                                    <h3><b> #somardiferentesexperiências </b></h3>
                                </span>

                            </div>

                        </div>
                
                    </div>

                </div>

            </div>
            
            <div className="box-informations">
                <div id={'#informations'} className="wrapper informations-items">

                    <div className="box-description-prerequisites">
                        <Prerequisites id={'requisitos'}/>
                        
                        <GeneralInformation id={'generalInformation'}/>
                    </div>

                    <div className="box-description-cards-timeline">
                        <CardTimeline id={'card-timeline'}/>
                    </div>
                </div>
            </div>

            <div className="box-informations">
                <div className="wrapper informations-items">
                    <div className={'inscrição-line-button'}>
                        <Button disabled className={'header-button'} size="large"> 
                            <a href='https://b2w.gupy.io/jobs/2593983' className={'header-button'}> inscrições encerradas! </a>
                        </Button>
                    </div>
                </div>
            </div>

            <div className={'box-benefits'}>
                
                <div className="wrapper">
                    <Benefits id={'Beneficios'}/>
                </div>
                
            </div>
            
        </Container>
    )
}