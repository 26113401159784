import React from "react";

import { Button } from '@material-ui/core'

import { Container } from "./style";

import { GeneralInformation } from "./components/GeneralInformation"
import { Prerequisites } from "./components/Prerequisites"
import { CardTimeline } from "./components/CardTimeline"
import { Benefits } from "./components/Benefits"

import americanas from './assets/logo-americanas.svg'
import shoptime from './assets/logo-shoptime.svg'
import submarino from './assets/logo-submarino.svg'

export const EstagioCorporativo = (...props) => {

    const brands = [
        { name: 'americanas', logo: americanas, description: 'americanas', link: 'https://www.americanas.com.br/' },
        { name: 'shoptime', logo: shoptime, description: 'shoptime', link: 'https://www.shoptime.com.br/' },
        { name: 'submarino', logo: submarino, description: 'submarino', link: 'https://www.submarino.com.br/' }
    ]
   
    const list_info_prog = [
        'Para todo o Brasil', 
        'Aberto para PcDs', 
        'Para todos os cursos', 
        'Totalmente gamificado e 100% online', 
    ]

    function toscroll(){
        const divElement = document.getElementById('#informations')
        divElement.scrollIntoView({ top: 0, behavior: "smooth"})
    }

    return(
        <Container>
            
            <div className="banner" role={'img'} alt={'A imagem possui fundo em amarelo escuro com simbolos de soma e igual na cor laranja.'}>

                <div className="banner-slogan">

                    <div className="banner-title-content">                   
                        
                        <div className="banner-people" role={'img'} alt={'Três blocos simulando janelas de navegadores: bloco da esquerda com o fundo em um gradiente em dois tons de vermelho, há uma jovem negra com o cabelo trançado longo e camisa cinza, acompanhando a hastag crescer para somar e a logo da americanas s.a. No bloco central e menor, o fundo é um gradiente de rosa claro com o "a" da americanas, a palavra estágio e a simulação de um botão branco escrito "inscrições abertas". O terceiro e último bloco, posicionado à direita, também com o fundo em um gradiente de vermelho, possui um rapaz branco sorrindo, usando um óculos de grau, uma camisa jeans por cima de uma camisa verde clara, com três hastags dentro de uma simulação de botão cada, a primeira com fundo amarelo escuro pra desenvolver, a segunda em fundo laranja pra inovar e a última em vermelho pra evoluir.'}></div>
                    
                    </div>

                </div>

            </div>

            <div className={'container-box'}>

                <div className={'wrapper'}>

                    <div className={'header-line-row'}>

                        <div className={'header-line-slogan'}>

                            <div className={'slogan'}>
                                
                                <div className="slogan-text">
                                    <h1 className="text-white"><b>#Vamos Crescer Para Somar!</b></h1> 
                                </div>
                           
                            </div>

                        </div>

                        <div className={'header-line-button invisible'}>
                            <Button onClick={toscroll} className={'header-button'} size="large">inscreva-se</Button>
                        </div>

                    </div>

                </div>

            </div>

            <div className={'body-description'}>

                <div className={'wrapper'}>

                    <div className={'body-description-content'}>

                        <div className={'body-description-col margin-right'}>

                            <h1 className={'body-description-label'}>
                                quem somos
                            </h1>

                            <div className={'body-description-row label-description'}>
                                Nascemos da união de Lojas Americanas e B2W, dona das marcas mais queridas da internet (Americanas, Submarino e Shoptime) e hoje somos uma multiplataforma de inovação tecnológica com atuação em varejo, e-commerce, logística, fintech e publicidade, impulsionadas por um forte motor de inovação.
                            </div>

                            <div className={'body-description-row label-description'}>
                                Somos um time diverso, com mais de 40 mil pessoas que somam experiências e pontos de vista diferentes, mas que compartilham dos mesmos valores e objetivos.                         
                            </div>

                            <div className={'body-description-row label-description'}>
                                Nosso propósito é somar o que o mundo tem de bom para melhorar a vida das pessoas.
                            </div>

                            <div className="bg-brands logo_brands">
                                {brands.map((brand) => (
                                    <div key={brand.name} className={`item ${brand.name}`}>
                                        <a href={brand.link} target={'_blank'} rel={'noreferrer'}><img src={brand.logo} alt={brand.description} /></a>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <div className={'body-description-col'}>

                            <h1 className={'body-description-label'}>o programa</h1>

                            <div className={'body-description-row label-description'}>
                                O Estágio na Americanas S.A. é para quem quer crescer para inovar, desenvolver e evoluir, em qualquer área da companhia! Aqui você impulsiona sua carreira de forma dinâmica, aprendendo diariamente com as melhores pessoas.
                            </div>
                           
                            <div className={'body-description-row label-description'}>

                                <div className={'label-description-col'}>
                                    o processo seletivo é:
                                </div>

                                <div className="list-program">
                                    {list_info_prog.map((info, index) => (
                                        <ul key={index}>
                                            <li>{info}</li>
                                        </ul>
                                    ))}
                                </div>
                            </div>

                            <div className={'body-description-row label-description'}>

                                Então, se você gosta de um ambiente colaborativo, com autonomia, tem foco em resultados e ama inovação, vem crescer com a gente!
        
                            </div>

                            <div className={'body-description-row label-description'}>

                                <span className="hashtag-color">
                                    <h3><b> #vamoscrescerparasomar </b></h3>
                                </span>

                            </div>

                        </div>
                
                    </div>

                </div>

            </div>
            
            <div className="box-informations" id={'#informations'} >
                <div className="wrapper informations-items">

                    <div className="box-description-prerequisites">
                        <Prerequisites id={'requisitos'}/>
                        
                        <GeneralInformation id={'generalInformation'}/>
                    </div>

                    <div className="box-description-cards-timeline">
                        <CardTimeline id={'card-timeline'}/>
                    </div>
                </div>

                <div className="wrapper informations-items">
                    <div className={'inscrição-line-button'}>
                        <Button disabled className={'header-button'} size="large"> 
                            <a href='https://b2w.gupy.io/job/eyJqb2JJZCI6MzAzNzQyOCwic291cmNlIjoiZ3VweV9wdWJsaWNfcGFnZSJ9?jobBoardSource=gupy_public_page' className={'header-button'}> inscrições encerradas! </a>
                        </Button>
                    </div>
                </div>

            </div>

            <div className={'box-benefits'}>
                
                <div className="wrapper">

                    <Benefits id={'Beneficios'}/>

                </div>
                
            </div>
            
        </Container>
    )
}