import styled from 'styled-components'

import background from './assets/images/background@2x.png'

export const Container = styled.div`
    
    background: url(${background}) calc(50% - 50px) bottom no-repeat ${props => props.theme.color.red400};
    background-position-y: 50%;

    .owl-carousel{
        height:280px;
    }

    .owl-stage-outer{
        height:399px;
    }

    @media only screen and (max-width: 1109px) {
        background-size: 205%;
        background-position: 55% bottom;
    }
    
    @media only screen and (max-width: 960px) {
        background-size: 175%;
        background-position: 47% bottom;
    }

    @media only screen and (max-width: 800px) {
        background-size: 200%;
        background-position: 47% bottom;
    }
    
    @media only screen and (max-width: 568px) {
        background-size: 270%;
        background-position: 48% bottom;
    }

    @media only screen and (max-width: 415px) {
        background-size: 280%;
        background-position: 48% bottom;
    }
    
    @media only screen and (max-width: 376px) {
        background-size: 245%;
        background-position: 46% bottom;
    }
    
    @media only screen and (max-width: 320px) {
        background-size: 280%;
        background-position: 48% bottom;
    }

    && {

        .owl-dots {
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: auto;
            position: absolute;
            top: auto;
            bottom: -10px;
            left: 0;
            right: 0;
        }

        .owl-dot {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 10px;
            border: 1px solid ${props => props.theme.color.neutral300};
            background-color: ${props => props.theme.color.neutral300};
        }

        .owl-dot.active {
            box-shadow: 0px 0px 0px 10px ${props => props.theme.color.orange500} inset;
            border: 1px solid ${props => props.theme.color.orange500} ;
        }

    }


`