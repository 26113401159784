import styled from 'styled-components'

import logo from 'assets/images/logo.svg'

export const Container = styled.div`
    
    background-color: ${props => props.theme.color.red900};
    padding: 0 ${props => props.theme.spacing.lg};
    
    @media screen and (max-width: 375px) {
        padding: ${props => props.theme.spacing.lg};
    }

    .footer-social {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 120px;
        flex-wrap: wrap;
    }

    .footer-logo {
        width: 320px;
        height: 38px;
        background: url(${logo}) no-repeat left center;
        background-size: contain;
    }

    @media screen and (max-width: 896px) {
        .footer-logo {
            opacity: 0;
        }
    }

    .social {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .social a {
        border: none;
        width: 43px;
    }
    
    .socialIcon {
        transition: all .3s;
        margin-right: ${props => props.theme.spacing.md};
    }
    .socialIcon:hover {
        filter: invert();
    }

    .social .socialIcon .a {
        stroke: none;
    }

    .footer-content {
        color: ${props => props.theme.color.neutral100}; 
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    .footer-list {
        display: flex;
        flex-direction: column;
        margin-bottom: ${props => props.theme.spacing.lg};
    }
    
    .footer-list > .footer-list {
        margin-top: ${props => props.theme.spacing.lg};
        margin-bottom: 0;
    }

    .footer-listTitle {
        width: fit-content;
        padding-bottom: ${props => props.theme.spacing.xs};
        border-bottom: ${props => props.theme.line.lg} solid ${props => props.theme.color.neutral100};
        margin-bottom: ${props => props.theme.spacing.sm};
    }

    .footer-list a {
        width: fit-content;
        font-size: ${props => props.theme.font.sm};
        color: ${props => props.theme.color.orange500};
        margin: ${props => props.theme.spacing.xs} 0;
    }
    
    .footer-list a:hover {
        opacity: 0.7;
    }

    .podCast{
        display: block;
        align-items: center;
        justify-content: center;
        word-break: break-all;
        padding-bottom: ${props => props.theme.spacing.lg};

        .footer-list {
            margin-bottom: 0;
        }
    }

    .spotify{
        width:31.6px;
        height: 31.6px;
        margin-bottom: ${props => props.theme.spacing.sm};
    }
    
`