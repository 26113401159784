import React from "react";

import OwlCarousel from "react-owl-carousel";

import { Search } from "shared/components";

import { DefaultBanner } from "./components/DefaultBanner";

import { Container } from "./style";

export const Banner = () => {
  const banners = [ DefaultBanner];

  const SingleBanner = banners && banners[0];

  return (
    <Container>
      {banners && banners.length > 1 ? (
        <OwlCarousel
          dots
          autoplay
          autoplayTimeout={5000}
          autoplayHoverPause
          loop
          items={1}
        >
          {banners.map((BannerComponent, key) => (
            <BannerComponent key={key} />
          ))}
        </OwlCarousel>
      ) : (
        <SingleBanner />
      )}

      <Search />
    </Container>
  );
};
