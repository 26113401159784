import styled from 'styled-components'

import mais from './assets/mais.svg'
import menos from './assets/menos.svg'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
     
    .header-title-card h2{
        margin-top: 50px;
    }

    .without-button{
        display: none;
    }

    .principles-container {
        counter-reset: section;

        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 325px;
        margin: ${props => props.theme.spacing.lg} 0;
    }
    
    .principle-box {
        padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.sm};
        margin-bottom: ${props => props.theme.spacing.md};
        border-radius: ${props => props.theme.round.md};
        border: ${props => props.theme.line.sm} solid ${props => props.theme.color.orange500};
        display: inline-block;
    }
    
    .card{
        display: flex;
    }
    
    .contador{
        counter-increment: section;

        margin: 0;
        align-items: center;
        position: relative;
        display: block;
        justify-content: center;
    }

    .contador:nth-child(odd)::before {

        content: counter(section);
        
        margin-top: 10px;

        margin-left: -18px;

        position: absolute;
        border-radius: 50%;
        padding-top: 2px;
        text-align: center;
        line-height: 32px;
        font-weight: bold;
        height: 34px;
        width: 34px;
        color: #fff;
        font-size: 20px;
        background-color: #ff9800;

        display:flex;
        justify-content: center;
        align-items: center;
    }

    .contador:nth-child(odd) {
        border-left: 1.5px solid #cecece;
        margin-right: 30px;
        padding-right: 0;
        margin-top: -5px;
    }
  
    @media only screen and (max-width: 840px) {
        
        .principle-box {
            width: 90%;
        }
    }

    @media only screen and (max-width: 768px) {
    
        .header-title-card h2 {
            margin-top: 0;
        }

        .header-card{
            width: 82%;
        }

        .principles-container {
            width: 80%;
        }
    }

    @media only screen and (max-width: 425px) {
        
        .header-card{
            width: 100%;
        }

        .principles-container {
            width: 90%;
        }
    }

    .principle-header {
        user-select: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-bottom: ${props => props.theme.line.sm} solid ${props => props.theme.color.neutral600};
        padding-bottom: ${props => props.theme.spacing.md};
    }

    .principle-box.--isClose .principle-header {
        margin: 0;
        
        border: none;
    }

    .principle-headerLabel {
        flex: 1;
        font-size: 16px;
        color: ${props => props.theme.color.red900};
        font-weight: 500;
    }

    .principle-headerIcon {
        width: 16px;
        height: 16px;
        transition: all .6s;
    }
    .principle-box.--isOpen .principle-headerIcon {
        background: url(${menos}) center center no-repeat;
    }
    .principle-box.--isClose .principle-headerIcon {
        background: url(${mais}) center center no-repeat;
        transform: rotateZ(180deg);
    }

    .button-box{
        margin-right: 25%;
    }

    .principle-content {
        pointer-events: none;
        margin-top: ${props => props.theme.spacing.md};
        transition: max-height .6s, opacity .3s;
        max-height: 888px;
    }
    
    .principle-box.--isClose .principle-content {
        max-height: 0px;
        margin-top: -20px;
        opacity: 0;
    }

`