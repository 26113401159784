import React from 'react'

import { Container } from './style'

export const Principles = (props) => {

    const [boxState, setBoxState] = React.useState({
        pessoas: false,
        metodos: false,
        resultados: false
    })

    const principles = [
        {
            name: 'pessoas',
            items: [
                'Um sonho grande e desafiador faz todo mundo remar na mesma direção',
                'Gente boa, trabalhando em equipe, crescendo na medida do seu talento e sendo recompensada por isso, é o maior ativo da empresa',
                'Escolher pessoas melhores do que eles próprios, treiná-las, desafiá-las e mantê-las é a principal tarefa dos administradores',
                'A liderança por exemplo pessoal é vital, tanto nas atitudes heroicas quanto nos pequenos gestos do dia-a-dia',
            ]
        },
        {
            name: 'métodos',
            items: [
                'Foco é essencial. Não dá para ser ótimo em tudo, tem que se concentrar no essencial.',
                'Tudo tem que ter um dono com responsabilidade e autoridade. O debate é bom, mas no final alguém tem que decidir.',
                'Bom senso é tão bom quanto grandes conhecimentos. O simples é melhor do que o complicado.',
            ]
        },
        {
            name: 'resultados',
            items: [
                'Ser obsessivo com os custos e despesas, que são as únicas variáveis sob o nosso controle, ajuda a manter a sobrevivência a longo prazo.',
                'Entender velocidade, urgência e complacência zero como fatores de vantagem competitiva duradouras.',
                'Ética, trabalho duro e consistência são a base para o crescimento da Companhia.',
            ]
        },
    ]

    const handleHeaderClick = (boxName) => {
        setBoxState({
            pessoas: false,
            metodos: false,
            resultados: false,
            [boxName]: !boxState[boxName]
        })
    }

    return (
        <Container {...props}>

            <h2>nossos <b>princípios</b></h2>

            <div className={'principles-container'}>

                {principles && principles.map((principle) => (

                    <div key={principle.name} className={`principle-box ${boxState[principle.name] ? '--isOpen' : '--isClose'}`}>

                        <div className={'principle-header'} onClick={handleHeaderClick.bind(this, principle.name)}>
                            <div className={'principle-headerLabel'}>{principle.name}</div>
                            <div className={'principle-headerIcon'}></div>
                        </div>

                        <div className={'principle-content'}>
                            <ul>
                                {principle.items.map((item, key) => (
                                    <li key={key}>{item}</li>
                                ))}
                            </ul>
                        </div>

                    </div>

                ))}

            </div>

        </Container>
    )
}