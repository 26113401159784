import React from 'react'

import Select from 'react-select'

import { Container } from './style.js'

export default React.forwardRef((props, ref) => {
    return (
        <Container>
            <Select ref={ref}
                classNamePrefix={'select'}
                noOptionsMessage={() => 'Nenhuma opção'}
                {...props}
            />
        </Container>
    )
})