import React from "react"

import { HashLink } from "react-router-hash-link"

import { scrollToElement } from "utils"

import { Container } from "./style"

import { ReactComponent as Linkedin } from "assets/images/social/linkedin.svg"

import { ReactComponent as Instagram } from "assets/images/social/instagram.svg"

import { ReactComponent as Glassdoor } from "assets/images/social/glassdoor.svg"

import { ReactComponent as Spotify } from "assets/images/social/spotify.svg"

import politicaPrivacidade from "./assets/files/política-de-privacidade.pdf"

export const Footer = () => {
  
  return (
    <Container>

      <div className={"wrapper"}>

        <div className={"footer-social"}>

          <div className={"footer-logo"}></div>

          <div className={"social"}>

            <a rel="noopener noreferrer" href="https://www.linkedin.com/company/americanas-sa/" target={"_blank"}>
              
              <div className={"socialIcon linkedin"}>
                <Linkedin />
              </div>
            
            </a>

            <a rel="noopener noreferrer" href="https://www.glassdoor.com.br/Visão-geral/Trabalhar-na-americanas-s-a-EI_IE10939.13,27.htm" target={'_blank'}>
                
                <div className={'socialIcon glassdoor'}>
                  <Glassdoor />
                </div>
                
            </a>
            
            <a rel="noopener noreferrer" href="https://www.instagram.com/carreiras.americanas/" target={"_blank"}>
              
              <div className={"socialIcon instagram"}>
                <Instagram />
              </div>
            
            </a>
          
          </div>

        </div>

        <div className={"footer-content"}>
          
          <div className={"footer-list"}>
            
            <div className={"footer-listTitle"}>sobre a americanas s.a.</div>
            
            <HashLink scroll={scrollToElement.bind(this)} to={{ pathname: "/", hash: "proposito" }}> nosso propósito </HashLink>

            {/* <HashLink scroll={scrollToElement.bind(this)} to={{ pathname: "/", hash: "valores" }}> nossos valores </HashLink> */}
            
            <HashLink scroll={scrollToElement.bind(this)} to={{ pathname: "/", hash: "principios" }}> nossos princípios </HashLink>
          
          </div>

          <div className={"footer-list"}>
            
            <div className={"footer-listTitle"}>programas</div>
            
            <span>programas para estudantes</span>

            <a rel="noopener noreferrer" href={"/estagio"} target={"_blank"}> estágio </a>

            <a rel="noopener noreferrer" href={"/estagio-tech"} target={"_blank"}> estágio tech </a>

            <a rel="noopener noreferrer" href={"/estagio-loja/"} target={"_blank"}> estágio em loja </a>
            
            <span>programas para recém-formados</span>
                                    
            <a rel="noopener noreferrer" href={"/programa-trainee"} target={"_blank"}> trainee </a>

            <span>programas para profissionais</span>
            
            <a rel="noopener noreferrer" href={"https://polotech.americanas.io/"} target={"_blank"}> americanas futuro polo tech </a>
          
          </div>

          <div className={"footer-list"}>

          <div className={"footer-list"}>

            <div className={"social podCast"}>

              <a rel="noopener noreferrer" href="https://open.spotify.com/show/5KkY7r5w0qC1y3HZLozGnV" target={"_blank"}>
                
                <div className={"socialIcon spotify"}>
                  <Spotify />
                </div>
              
              </a>
              
              <div className={"footer-list"}> SomarCast, o podcast da </div>
              <div className={"footer-list"}> Americanas s.a. com Nina Silva. </div>
              <div className={"footer-list"}> Ouça já! </div>

            </div>


           </div>
            
            <div className={"footer-listTitle"}> vagas </div>

            {/* <div className={"footer-list"}>
             
              <div className={"footer-listTitle"}> relações com investidores </div>
            
            </div> */}

            <div className={"footer-list"}>

              <a rel="noopener noreferrer" href={politicaPrivacidade} target={"_blank"}> Política de privacidade </a>
            
            </div>
          
          </div>
        
        </div>
      
      </div>
    
    </Container>
  )

}
