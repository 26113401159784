import React from "react"

import { useTheme } from "styled-components"

import { Container } from "./style"

import { OpportunityCard } from "./components/OpportunityCard"

export const Opportunity = (props) => {
  const theme = useTheme();

  return (
    <Container {...props}>
      <div className={"wrapper"}>
        <h1>
          nossas <b>oportunidades</b>
        </h1>

        <div className={"opportunities"}>
          <OpportunityCard
            color={theme.color.orange900}
            subtitle={"estudantes"}
            description={
              "Confira os programas de entrada voltados especialmente para estudantes."
            }
            links={[
              { label: "estágio", link: "/estagio" },
              { label: "estágio tech", link: "/estagio-tech" },
              { label: "estágio em loja" , link: "/estagio-loja" },
            ]}
            open
          />

          <OpportunityCard
            color={theme.color.red500}
            subtitle={"recém-formados"}
            description={"Programas de entrada pensados para recém-formados."}
            links={[
              {label: "trainee", link: "/programa-trainee"},
            ]}
            open
            seeMore={"/vagas/jr"}
          />

          <OpportunityCard
            color={theme.color.red600}
            subtitle={"profissionais"}
            description={
              "Programas para profissionais de diversas áreas de atuação."
            }
            links={[
              {
                label: "americanas futuro polo tech",
                link: "https://polotech.americanas.io/",
              },
            ]}
            open
            seeMore={"/vagas"}
          />
        </div>
      </div>
    </Container>
  )
}
