import styled from 'styled-components'


import imagemPessoas from './assets/imagem-banner-pessoas.svg';
import logoTrainee from './assets/logo-atrainee-vermelho.svg'
import hashtag from './assets/hashtag.svg';


export const Container = styled.div `

    color: #555960;
    width: 100%;
    transition: all .3s;

    h1 {
        margin: 0;
    }

    .banner {
        background: #ff9800;
        height: 399px;
    }

    .banner-slogan {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        flex-direction: column;
        height: inherit;
        background-size: 100% 100%;

        flex-wrap: wrap;
    }

    .banner-pessoas{
        background: url(${imagemPessoas}) no-repeat right;
        height: inherit;
        width: 100%;
    }

    .banner-title {
        width: calc(75%);
        z-index: 1;
        position: absolute;
    }
   
    .banner-slogan-title {
        background: url(${logoTrainee}) no-repeat center;
    
        height: 230px;
        background-size: calc(70%);
        max-width: 670px;
    }

    .inscricoes-aberta {
        font-family: 'Roboto-Medium';
        color: white !important;
        padding-left: 116px;
        font-weight: unset;
        font-size: calc(1.375rem + 1.5vw) !important;
        
        padding-left: 7.2%;
        margin-bottom: 6%;
    }

    .hashtag-info{
        background: url(${hashtag}) no-repeat right bottom;
        height: inherit;
        width: 100%;
        bottom: 0;
    }

    .container-box{
        background: #F80032;
        padding: 0 20px;
    }

    .header-line-row{
        display: flex;
    }

    .header-line-slogan{
        min-height: 150px;
        width: 80%;
    }

    .slogan{
        height: calc(100%);
        align-items: center;
        display: flex;
    }

    .text-white{
        color: #fff;
    }
   
    .header-line-button{
        margin: auto 0 auto auto;
    }

    .without-button{
        display: none;
    }

    .header-button{
        background-color: #FF9800;
        border-radius: 8px;
        float: right;
        color: #ffffff;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        min-width: 240px;
    }

    .header-button:hover{
        background-color: ${props => props.theme.color.orange500};
    }

    .body-description{
        background: rgba(247, 117, 165, 0.3);
        height:auto;
        width: 100%;
    }

    .body-description-content{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
        padding-left: 40px;
    }

    .body-description-label{
        font-size: 30px;
        color: #BB0F33;
 
        padding: 20px;
       
        line-height: 42px;
        margin-top: 50px;
    }

    .body-description-row{
        margin: auto;
        padding: 0 6.5%;
        padding-right: 0px;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    .body-description-col{
        width: calc(95%);
    }

    .body-description-row{
        margin-bottom: 2%;
        font-size: 16px;
    }

    .label-description-col{
        font-style: normal;
        color: #F80032;
        font-weight: bold;

        line-height: 24px;

        padding-bottom: 20px;
    }

    .label-description{
        padding: 0 0 2% 4%;
    }

    .list-program ul{
        font-size: 16px;
        line-height: 27px;
    }

    .list-program ul li{
        display: flex;
        align-items: flex-start;
        margin-bottom: ${props => props.theme.spacing.sm};
    }
   
    .list-program ul li::before {
        content: "";
        min-width: 4px;
        min-height: 4px;
        margin-right: ${props => props.theme.spacing.md};
        margin-top: ${props => props.theme.spacing.xs};
        border: 2px solid #ff9800;

        border-radius: ${props => props.theme.round.sm};
    }

    .hashtag-color{
        color: #bb0f33;
    }

    .bg-brands{
        background-color: #fff;
    }

    .logo_brands{
        display:flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        padding: 0 325px;
    }

    .logo_brands a {
        border-bottom: none;
    }

    .logo_brands img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box-informations{
        min-height:506px;
        width: 100%;
        display: -webkit-box;

        background: rgba(255, 152, 0, 0.3);
    }

    .box-description-prerequisites{
        width: calc(50%);
    }

    .box-description-cards-timeline{
        width: calc(50%);
        padding-right: 14%;
    }

    .banner span {
        display: block;
        font-weight: bold;
        margin-left: 20px;
        max-width: 280px;
    }

    @media screen and (max-width: 950px){
        
        .banner-slogan{
            width: 100%;
            background-size: contain;
            height: inherit;
            background-position: bottom;
        }

        .banner-pessoas{
            background-size: contain;
        }

        .rectangle_mulher{
            position: absolute;
            background-size: auto;
        }

        .banner-slogan-title{
            background-size: calc(90%);
        }

        .banner-slogan-Sub-Title{
            background-size: calc(80%);
        }
    }

    @media only screen and (min-width: 1440px) and (max-width: 2560px){

        .banner-slogan {
            display: flex;
            align-items: center;
            justify-content: center;
            height: inherit;
        }

        .header-button{
            min-width: 240px;
        }
    }

    @media only screen and (min-width: 1024px) and (max-width: 2560px){
        
        .body-description-col {
            max-width: 448px;
        }

        .header-button{
            min-width: 240px;
        }
    }
    
    @media only screen and (min-width: 600px) and (max-width: 1024px){

        .banner-sloganTitle {
            height: 90px;
        }

        .header-button{
            min-width: 240px;
        }

        .box-description-cards-timeline{
            padding-right: 0;
        }
    }

    @media only screen and (min-width: 2560px){

        .banner-title {
            margin-right: 0;
        }

        .container-box .wrapper{
            margin: 0 16%;
        }

        .banner-slogan-Sub-Title{
            margin-right: 30%;
        }

        .inscricoes-aberta{
            padding-left: 7.5%;
        }
        
        .box-description-cards-timeline{
            padding-right: 24.5%;
        }

        .logo_brands{
            padding: 0 680px;
        }
    }
   

    @media only screen and (max-width: 1440px){

        .banner-title{
            margin-right: 20%;
        }

        .banner-slogan-Sub-Title{
            margin-left: 2%;
        }

        .inscricoes-aberta{
            padding-left: 7.5%;
        }

        .box-description-cards-timeline{
            padding-right: 5%;
        }

        .logo_brands{
            padding: 0 125px;
        }
    }

    @media screen and (width: 1280px){
     
        .banner-title{
            margin-right: 20%;
            width: calc(83.4%);
            z-index: 1;
            position: absolute;
        }
    }

    @media screen and (max-width: 1024px){

        .banner{
            height: 285px;
        }

        .banner-title{
            margin-right: 36%;
        }

        .banner-pessoas{
            background-size: contain;
        }

        .banner-slogan-title{
            background-size: calc(50%);
        }

        .banner-slogan-Sub-Title{
            background-size: calc(50%);
        }

        .inscricoes-aberta{
            padding-right: 15%;
            margin-top: -6%;
            padding-bottom: 85px;
            padding-left: 20%;

            margin-bottom: 0;
        }

        .logo_brands {
            padding: 0 20px;
        }

        .box-description-cards-timeline {
            padding-right: 0;
        }
    }

    @media screen and (max-width: 820px){

        .banner{
            height: 699px;
        }

        .banner-pessoas{
            background-position: bottom;
        }

        .banner-title {
            margin-right: 10%;
            margin-top: -50%;
        }

        .banner-slogan-title {
            background-size: calc(70%);
        }

        .inscricoes-aberta{
            padding-right: 8%;
            padding-bottom: 85px;
            padding-left: 15%;
        }

        .header-line-row{
            padding: 0 20px;
            height: 200px;
        }

        .body-description{
            padding: 0 10px;
        }

        .box-description-prerequisites{
            padding: 0 10px;
        }

        .box-description-cards-timeline{
            padding: 0 10px;
        }

        .box-informations{
            display: flex;
            flex-direction: column;

            padding: 0 10px;
        }

        .box-description-cards-timeline{
            width: calc(100%);
        }

        .box-description-prerequisites{
            width: calc(100%);
            display: flex;
            justify-content: center;
        }

        .body-description-content{
            flex-direction: column;
            padding: 50px;
        }

        .body-description-col{
            width: calc(100%);
        }

        .logo_brands{
            .item{
                width: calc(50%);
                display: flex;
                flex-direction: row;
                justify-content: space-around;
   
                img{
                    width: calc(100%);
                }
            }
        }

        .body-description-label{
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            margin: 0 10px;
            padding-left: 0;
            padding: 0 2% 2%;
        }

        .label-description-col{
            font-size: 16px;
        }
    }

    @media screen and (max-width: 540px) {

        .banner{
            height: 400px;
        }

        .inscricoes-aberta{
            padding-right: 8%;
            padding-bottom: 85px;
            padding-left: 15%;
        }

        .body-description-row{
            margin-bottom: 5px;
        }

        .body-description-content{
            margin:0;
            padding: 30px;
        }

        .logo_brands{
            .item{

                img{
                    width: calc(90%);
                    height: 70px;
                }
            }    
        }

        .header-line-row{
            display: flex;
            flex-direction: column;
            padding: 0;
        }

        .header-line-slogan{
            width: 100%;
            height: 90px;
            padding: initial;
        }

        .header-line-button{
            margin: auto;
            margin-top: -15px;
        }

        .banner-title{
            width: 100%;

            margin-right: 0;
            margin-top: -45%;
        }

        .banner-slogan-title {
            margin-left: 5%;
            background-size: calc(45%);
        }

        .banner-slogan-Sub-Title {
            display: flex;
            justify-content: center;
            margin-top: -8%;
        }

        .slogan{
           max-width: 100%;
           margin-left: 10%;
        }

        .slogan-text{
            padding-top: 10%;
        }

        .box-description-prerequisites{
            padding-left: 0;
    }
}

    @media screen and (max-width: 425px) {

        .banner-title{
            margin-right: -5%;
            margin-top: -50%;
        }
        
        .banner-slogan-title{
            width: calc(70%);
            margin-left: 10%;

            background-size: calc(70%);
        }

        .banner-slogan-Sub-Title {
            margin-top: 0;
        }

        .inscricoes-aberta{
            padding-right: 8%;
            margin-top: -16%;
            padding-bottom: 85px;

            padding-left: 0;
        }

        .body-description-content{
            padding: 30px 0;
        }

        .slogan{
           margin-left: 0;
        }

        .slogan-text{
            padding-top: 18%;

            h1{
                font-size: 2.3em;
            }
        }

        .header-line-button{
            margin-top: 5px;
        }

        .hashtag-info{
            background-size: contain;
        }
    }

    @media only screen and (max-width: 320px){

        .banner{
            height: 360px;
        }
        .banner-title{
            margin-right: 0;
        }

        .banner-slogan-title{
            width: 100%;
            margin: auto;
        }

        .inscricoes-aberta{
            padding-right: 2%;
        }

        .slogan-text h1{
            font-size: 2em;
        }
        
        .header-line-button{
            margin-top: 15px;
        }
    }


`

