import React from 'react'

import {
    Banner,
    Brand,
    About,
    Opportunity
} from './components'

import { Container } from './style'

export const Home = () => {

    return (
        <Container>
            <Banner />
            <Brand />
            <About id={'sobre'} />
            <Opportunity id={'programas'} />
        </Container>
    )
}