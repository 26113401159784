import React from 'react'

import { Route } from 'react-router-dom'
import { Routes } from 'routes'

import { Page, Header, PrivacyPolicy, Footer } from 'shared/components'

const App = () => {

  return (
    <>

      <Route component={Header} />

      <Page>
        <Routes />
      </Page>

      <Footer />

      <PrivacyPolicy />

    </>
  )
}

export default App
