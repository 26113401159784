import React from 'react'

import { Container } from './style'

import salary from './assets/images/icon-salary.svg'
import discount from './assets/images/icon-discount.svg'
import insurance from './assets/images/icon-life-insurance.svg'
import meal from './assets/images/icon-meal.svg'
import allya from  './assets/images/icon-allya.svg'

export const Benefits = (props) => {

    const benefits = [
        { name: 'Bolsa compatível  com o mercado', logo: salary, description: 'Bolsa compatível  com o mercado' },
        { name: 'Vale refeição ', logo: meal, description: 'Vale refeição' },
        { name: 'Seguro de vida', logo: insurance, description: 'Seguro de vida' },
        { name: 'Descontos nas nossas lojas online e físicas*', logo: discount, description: 'Descontos nas nossas lojas online e físicas*'  },
        { name: 'Clube de descontos Allya*', logo: allya, description: 'Clube de descontos Allya*' },
    ]

    return (
        <Container {...props}>
            <div className={'benefits-container'}>
                
                <div lassName={'benefits-header'}>
                    <div className={'benefits-headerLabel'}> <h1>Benefícios</h1> </div>
                </div>

                {benefits && benefits.map((benefits) => (
                    
                    <div key={benefits.logo} className={`item benefits-box ${benefits.logo}`}>
                        
                        <div className={'benefits-content'}>
                            <div className={'benefits-box-icon'}>
                                <img src={benefits.logo} alt={benefits.description} />
                            </div>
                            <div>
                                <h2>{benefits.name}</h2>
                            </div> 

                        </div>
       
                    </div>
                ))}

                <div lassName={'benefits-observation'}>
                    <div className={'benefits-headerLabel'}> <h2>*Após 3 meses</h2> </div>
                </div>

            </div>
        </Container>
    )
}