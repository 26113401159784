import React from 'react'

import { Container } from './style'

export const Prerequisites = (props) => {

    const prerequisites = [
        {
            title: 'o que você precisa ',
            titlebold: 'para participar',
            items: [
                'Ter matrícula ativa em uma universidade',
                'Ter previsão de formação: até Dez/ 2024',
                'Ter disponibilidade para estagiar 6 horas diárias (30h semanais)'
            ],
        }
    ]

    return (
        <Container {...props}>
                {prerequisites && prerequisites.map((prerequisites) => (

                    <div key={prerequisites.title} className={'prerequisites-container'}>

                        <div className={'prerequisites-box'}>

                            <div className={'prerequisites-header'}>
                                <div className={'prerequisites-headerLabel'}> 
                                    <h1>{prerequisites.title}<b>{prerequisites.titlebold}</b></h1> 
                                </div>
                            </div>

                            <div className={'prerequisites-content'}>
                                <ul>
                                    {prerequisites.items.map((item, key) => (
                                        <li key={key}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                           
                        </div>

                    </div>

                ))}
        </Container>
    )
}

