import { produce } from 'immer'

export const types = {
    setTheme: 'theme/setTheme',
    toggleTheme: 'theme/toggleTheme',
}

const initialState = {
    currentTheme: 'default'
}

const reducer = (state = initialState, action) => {

    return produce(state, draft => {

        switch (action.type) {
            case types.setTheme: draft.currentTheme = action.payload.currentTheme; break;
            case types.toggleTheme: draft.currentTheme = state.currentTheme === 'default' ? 'dark' : 'default'; break;
            default:
        }

    })

}

export const setTheme = (payload) => {
    return {
        type: types.setTheme,
        payload
    }
}

export const toggleTheme = () => {
    return {
        type: types.toggleTheme
    }
}

export default reducer