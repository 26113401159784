import styled from 'styled-components';

import background from './assets/images/background.svg';
import slogan from './assets/images/slogan.svg';
import selo from './assets/images/selos_vertical_a_1MO.svg';
import americanas_sa from './assets/images/americanas_sa.svg';
export const Container = styled.div`
  position: relative;
  height: 420px;
  /* Descomentar quando add mais de um banner */
  /* background: url(${background}) calc(50% - 50px) bottom no-repeat ${(
    props
  ) => props.theme.color.red400}; */

  h1 {
    color: #ffff;
  }

  .slogan {
    position: absolute;
    top: 30px;
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
  }

  @media only screen and (max-width: 960px) {
    .slogan {
      top: 50px;
      left: 75%;
      background: url(${slogan}) no-repeat;
      background-size: contain;
      transform: translateX(-40%);

      h1 {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 568px) {
    .slogan {
      top: 50px;
      left: 65%;
    }
  }

  @media only screen and (max-width: 425px) {
    .slogan {
      top: 50px;
      left: 60%;
      transform: translateX(-40%);
    }
  }

  @media only screen and (max-width: 320px) {
    .slogan {
      top: 50px;
      left: 58%;
      transform: translateX(-40%);
    }
  }

  .selo {
    z-index: 1;
    width: 136px;
    height: 290px;
    right: 0;
    background: url(${selo}) no-repeat;
    background-size: contain;
    position: absolute;
  }

  .americanassa {
    z-index: 1;
    width: 130px;
    height: 235px;
    right: 7px;
    top: 475px;
    background: url(${americanas_sa}) no-repeat;
    background-size: contain;
    position: absolute;
  }

  @media only screen and (max-width: 960px) {
    .selo {
      display: none;
    }

    .americanassa {
      display: none;
    }
  }

  @media only screen and (max-width: 1109px) {
    background-size: 205%;
    background-position: 55% bottom;
  }

  @media only screen and (max-width: 960px) {
    background-size: 175%;
    background-position: 47% bottom;
  }

  @media only screen and (max-width: 800px) {
    background-size: 200%;
    background-position: 47% bottom;
  }

  @media only screen and (max-width: 568px) {
    background-size: 270%;
    background-position: 48% bottom;
  }

  @media only screen and (max-width: 415px) {
    background-size: 280%;
    background-position: 48% bottom;
  }

  @media only screen and (max-width: 376px) {
    background-size: 245%;
    background-position: 46% bottom;
  }

  @media only screen and (max-width: 320px) {
    background-size: 280%;
    background-position: 48% bottom;
  }
`;
