import styled from 'styled-components'

import play from './assets/images/play.svg'
import pause from './assets/images/pause.svg'

export const Container = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & .shape1, & .shape2 {
        position: absolute;
        bottom: 150px;
        left: -80px;
        width: 150px;
        height: 35px;
        border: 2px solid ${props => props.theme.color.red500};
    }
    
    & .shape2 {
        bottom: 80px;
        border: none;
        background-color: ${props => props.theme.color.red500};
    }

    & .shape3, & .shape4 {
        position: absolute;
        top: 100px;
        right: -50px;
        width: 70px;
        height: 15px;
        background-color: ${props => props.theme.color.orange900};
    }

    & .shape4 {
        transform: rotateZ(90deg);
    }

    @media only screen and (max-width: 774px) {
        & .shape1, & .shape2, & .shape3, & .shape4 {
            display: none;
        }
    }

    .video-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 466px;
        margin: ${props => props.theme.spacing.lg} 0;
    }

    @media only screen and (max-width: 774px) {
        .video-container {
            width: 90%;
            height: auto;
            margin-top: ${props => props.theme.spacing.sm};
        }
    }

    .video-container::after {
        position: absolute;
        content: "";
        pointer-events: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
        transition: all .3s;
        filter: invert();
    }

    .video-container.--isPlaying:hover::after {
        background: url(${pause});
        opacity: 0.5;
    }

    .video-container.--isPaused::after {
        background: url(${play});
        opacity: 0.5;
    }

    video {
        width: 100%;
        height: 100%;
    }   

`