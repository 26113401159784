import React from 'react'
import { useParams } from 'react-router-dom'

import api from 'services/api'

import SearchIcon from '@material-ui/icons/Search'
import { Button } from '@material-ui/core'

import { Search } from 'shared/components'

import Select from './components/Select'

import { Opportunity } from 'pages/Home/components'

import { Container } from './style'

export const Vagas = () => {

    const { qSearch } = useParams()

    const searchRef = React.useRef()

    const defaultSelectOption = { value: '*', label: 'Todas' }

    const [isFirstLoad, setIsFirstLoad] = React.useState(true)
    const [isLoading, setIsLoading] = React.useState(true)
    const [isFetchingMore, setIsFetchingMore] = React.useState(false)
    const [fixedSearch, setFixedSearch] = React.useState(false)

    const [jobs, setJobs] = React.useState()
    const [jobList, setJobList] = React.useState([])
    const [locations, setLocations] = React.useState()
    const [location, setLocation] = React.useState(defaultSelectOption)
    const [departments, setDepartments] = React.useState()
    const [department, setDepartment] = React.useState(defaultSelectOption)
    const [search, setSearch] = React.useState()
    const [page, setPage] = React.useState(0)

    const scrollToSearch = () => {

        const searchElement = document.querySelector('#staticSearch').getBoundingClientRect()

        window.scrollTo({
            top: (searchElement.top + window.scrollY),
            left: 0,
            behavior: 'smooth'
        })

    }

    React.useEffect(() => {

        const loadJobs = async () => {

            const { data: locations } = await api.vagas.get('jobs/locations')
            const { data: departments } = await api.vagas.get('jobs/departments')

            let locationsOrdered = locations.map((location) => ({
                value: location,
                label: location
            }))

            setLocations([defaultSelectOption, ...locationsOrdered])

            let departmentsOrdered = departments.map((department) => ({
                value: department,
                label: department
            }))

            setDepartments([defaultSelectOption, ...departmentsOrdered])

            setSearch(qSearch && qSearch.toLowerCase())

            setIsLoading(false)

            if (qSearch)
                scrollToSearch()

            setIsFirstLoad(false)

        }

        loadJobs()

        const fixSearch = (e) => {

            const jobResult = document.querySelector('#job-result')

            if (jobResult) {
                const jobResultTop = jobResult.getBoundingClientRect().top
                setFixedSearch(window.pageYOffset > jobResultTop + 90)
            }

        }

        document.addEventListener('scroll', fixSearch)

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
            })
        }, 100)

        return () => {
            document.removeEventListener('scroll', fixSearch)
        }

    }, [])

    React.useEffect(() => {

        const filterSearch = async () => {

            setIsFetchingMore(true)

            const positionQuery = search ? `position=${search}&` : ''
            const departmentQuery = department.value !== '*' ? `department=${department.value}&` : ''
            const locationQuery = location.value !== '*' ? `location=${location.value}&` : ''

            const { data: jobs } = await api.vagas.get(`jobs?${departmentQuery}limit=10&${locationQuery}page=${page}&${positionQuery}sortBy=position`)

            setJobs(jobs)

            if (jobs.first) {
                setJobList(jobs.content)
                if (!isFirstLoad)
                    scrollToSearch()
            } else {
                setJobList(currentState => [...currentState, ...jobs.content])
            }

            setIsFetchingMore(false)

        }

        filterSearch()

    }, [location, department, search, page])

    const scrollToMobileSearch = () => {
        window.scrollTo({
            top: 270,
            left: 0,
            behavior: 'smooth'
        })
    }

    const handleSearch = (value) => {
        if (value !== search) {
            setPage(0)
            setSearch(value && value.trim().toLowerCase())
        }
    }

    const handleLocationChange = (option) => {
        setPage(0)
        setLocation(option)
    }

    const handleDepartmentChange = (option) => {
        setPage(0)
        setDepartment(option)
    }

    const handleLoadMoreClick = () => setPage(page + 1)

    const searchComponent = () => (
        <>
            <div className={'input-container'}>
                <div className={'input-label'}>vaga</div>
                <div className={'input-element'}>
                    <Search ref={searchRef} onSearch={handleSearch} defaultValue={search} />
                </div>
            </div>

            <div className={'input-container'}>
                <div className={'input-label'}>localidade</div>
                <div className={'input-element'}>
                    <Select options={locations} value={location} onChange={handleLocationChange} defaultValue={defaultSelectOption} />
                </div>
            </div>

            <div className={'input-container'}>
                <div className={'input-label'}>área de interesse</div>
                <div className={'input-element'}>
                    <Select options={departments} value={department} onChange={handleDepartmentChange} defaultValue={defaultSelectOption} />
                </div>
            </div>
        </>
    )

    return (
        <Container>

            <div className={'banner'}>
                <div className={'wrapper'}>
                    <div className={'banner-slogan'}>
                        <div className={'banner-sloganTitle'}></div>
                    </div>
                </div>
            </div>

            <div className={'job'}>

                {jobList && jobList.length > 10 &&
                    <div className={`job-controls fixed ${fixedSearch ? '--isVisible' : ''}`}>
                        <div className={'wrapper'}>
                            {searchComponent()}
                        </div>
                    </div>
                }

                <div className={'wrapper'}>

                    <h1>nossas <b>vagas</b></h1>

                    <div id={'staticSearch'} className={'job-controls'}>
                        {searchComponent()}
                    </div>

                    <div className={`job-mobileSearch ${fixedSearch ? '--isVisible' : ''}`} onClick={scrollToMobileSearch}>
                        <SearchIcon />
                    </div>

                    <div id={'job-result'} className={'job-result'}>

                        <div className={'result-title'}>
                            {
                                isLoading ? 'Carregando...' :
                                    jobs && jobs.totalElements ?
                                        <>
                                            {jobs ? <><span>{jobs.totalElements}</span> {jobs.totalElements > 1 ? 'vagas' : 'vaga'}</> : ''}
                                            {location && location.value !== '*' ? <> em <span>{location.label}</span></> : ''}
                                            {department && department.value !== '*' ? <> para <span>{department.label}</span></> : ''}
                                        </> : 'Nenhuma vaga'
                            }
                        </div>

                        <div className={'result-body'}>
                            {jobList && jobList.map((job) => (
                                <a key={job.id} href={job.link} rel={'noreferrer'} target={'_blank'}>
                                    <div className={'result-item'}>
                                        <div className={'result-itemPosition'}>{job.position}</div>
                                        <div className={'result-itemLocation'}>{job.location}</div>
                                        <div className={'result-itemDepartment'}>{job.departmentName}</div>
                                    </div>
                                </a>
                            ))}
                        </div>

                        {
                            jobs && !jobs.last
                                ? <Button disabled={isFetchingMore} onClick={handleLoadMoreClick}>{isFetchingMore ? 'carregando...' : 'carregar mais'}</Button>
                                : ''
                        }

                    </div>

                </div>
            </div>

            <Opportunity />

        </Container>
    )
}