import styled from 'styled-components'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
    .purpose-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 340px;
        height: 212px;
        color: ${props => props.theme.color.neutral100};
        background-color: ${props => props.theme.color.red900};
        margin: ${props => props.theme.spacing.xl} 0;
    }

    .purpose-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto-Medium';
        font-size: 20px;
    }
    
    .purpose-text span {
        margin-bottom: ${props => props.theme.spacing.xs};
    }
    .purpose-text span:nth-of-type(1) b {
        font-size: 42px;
    }
    .purpose-text span:nth-of-type(3) b {
        font-size: 32px;
    }
    .purpose-text span:nth-of-type(4) b {
        font-size: 22px;
    }

    .purpose-container .shape1, .purpose-container .shape2 {
        position: absolute;
        top: -10px;
        left: -30px;
        width: 80px;
        height: 30px;
        background-color: ${props => props.theme.color.neutral100};
    }
    
    .purpose-container .shape2 {
        top: -5px;
        left: -35px;
        transform: rotateZ(90deg);
    }
    
    .purpose-container .shape3, .purpose-container .shape4 {
        position: absolute;
        top: -6px;
        right: -30px;
        width: 60px;
        height: 15px;
        background-color: ${props => props.theme.color.orange500};
    }
    
    .purpose-container .shape4 {
        transform: rotateZ(90deg);
    }
    
    .purpose-container .shape5, .purpose-container .shape6 {
        position: absolute;
        bottom: 5px;
        left: -30px;
        width: 60px;
        height: 15px;
        background-color: ${props => props.theme.color.orange900};
    }
    
    .purpose-container .shape6 {
        bottom: -20px;
    }
    
    .purpose-container .shape7, .purpose-container .shape8 {
        position: absolute;
        top: 60%;
        right: -30px;
        width: 60px;
        height: 15px;
        background-color: ${props => props.theme.color.neutral100};
    }
    
    .purpose-container .shape8 {
        top: 73%;
    }
    
    & .shape9, & .shape10 {
        position: absolute;
        top: 55%;
        left: -20px;
        width: 30px;
        height: 7px;
        background-color: ${props => props.theme.color.orange500};
    }
        
    & .shape10 {
        transform: rotateZ(90deg);
    }

    & .shape11, & .shape12 {
        position: absolute;
        top: 60px;
        right: -50px;
        width: 100px;
        height: 30px;
        border: ${props => props.theme.line.md} solid ${props => props.theme.color.orange500};
    }
    
    & .shape12 {
        top: 110px;
        right: -100px;
        border: none;
        background-color: ${props => props.theme.color.orange900};
    }

    & .shape13 {
        position: absolute;
        left: -90px;
        bottom: 90px;
        width: 50px;
        height: 60px;
    }

    & .shape13 svg {
        width: 180px;
        height: 180px;
    }
    
    & .shape13 svg .a {
        font-family: 'Roboto-Black';
    }

    @media only screen and (max-width: 774px) {
        & .shape9, & .shape10, & .shape11, & .shape12, & .shape13 {
            display: none;
        }
    }

`