import React from 'react'

import { Container } from './style'
import { TrailCards } from '../TrailCards'

export const Trails = (props) => {

    const [boxStateTrail, setboxStateTrail] = React.useState({
        cards: false,
    })

    const trailBoxClick = (boxName) => {
        setboxStateTrail({
            [boxName]: !boxStateTrail[boxName]
        })
    }

    const careerTrails = [
        {
            title: 'trilha de desenvolvimento',
            subtitle: 'Essa trilha é pra quem quer aprender sobre:' , 
            items: [
                'Linguagens de Programação (javascript, html, css, java, go e muito mais!)',
                'Versionamento de código', 
                'Ferramentas e conceitos da engenharia de software',
                'Desenvolvimento, implantação e manutenção de aplicações',
            ],
            class:'development-trail',
            trailsCard:
            [
                {
                    name: 'Fundamentos',
                    subtitle:'Conhecimentos em:',
                    items: [
                        'Tecnologias',
                        'Ferramentas e conceitos',
                        'Linguagens de programação em back e frontend'

                    ]
                },
                {
                    name: 'Código na prática',
                    subtitle:'',
                    items: [
                        'Junto às aulas teóricas, os estagiários resolvem exercícios reais da companhia para colocarem os conhecimentos adquiridos em prática.'
                    ]
                },
                {
                    name: 'Job Rotation',
                    subtitle:'',
                    items: [
                        'Os estagiários passarão por diversas equipes da companhia, já aplicando o conhecimento das aulas.'
                    ]
                },
                {
                    name: 'Mentoria',
                    subtitle:'',
                    items: [
                        'Acompanhamento com mentores, instrutores e time de Gente & Gestão ao longo de seu desenvolvimento no programa.'
                    ]
                },
                {
                    name: 'PET Project',
                    subtitle:'',
                    items: [
                        'Em um ambiente colaborativo, irão desenvolver desafios reais da companhia, através de muita troca e aprendizado.'
                    ]
                }
            ],
            rel:'inscrições encerradas!',
            link:'https://b2w.gupy.io/job/eyJqb2JJZCI6MTQ2MjE4Nywic291cmNlIjoiZ3VweV9wdWJsaWNfcGFnZSJ9?jobBoardSource=gupy_public_page',
            details:'conheça a trilha de desenvolvimento' 
        },
        {
            title: 'trilha de dados',
            subtitle: 'Essa trilha é pra quem quer aprender sobre:',
            items: [
                'Ferramentas, modelagem e visualização de dados', 
                'Linguagem de Programação (SQL & Python)',
                'Data storytelling, web analytics e inteligência artificial',
                'Criação de produtos, realização de estudos analíticos e apresentações visuais para validar hipóteses',
            ],
            class:'data-trail',
            trailsCard:
            [
                {
                    name: 'Fundamentos',
                    subtitle:'Conhecimentos em:',
                    items: [
                        'Modelagem de dados',
                        'Ferramentas de dados e linguagens de programação',
                        'Metodologias ágeis',
                        'Segurança da informação'
                    ]
                },
                {
                    name: 'Dados na prática',
                    subtitle:'Aplicação dos fundamentos em ciclos de aulas práticas de:',
                    items: [
                        
                        'Web analytics',
                        'UX, priorização e data discovery',
                        'Integração de dados',
                        'Evolução dos dados',
                        'Visualização de dados',
                        'Inteligência artificial'
                    ]
                },
                {
                    name: 'Job Rotation',
                    subtitle:'',
                    items: [
                        'Os estagiários passarão por diversas equipes da companhia tendo encontros entre eles para discutirem o aprendizado.'
                    ]
                },
                {
                    name: 'Mentoria',
                    subtitle:'',
                    items: [
                        'Acompanhamento com mentores, instrutores e time de Gente & Gestão ao longo de seu desenvolvimento no programa.'
                    ]
                },
                {
                    name: 'Bootcamp Project',
                    subtitle:'',
                    items: [
                        'Ao longo de todo o programa, irão desenvolver desafios reais em dados do nosso negócio, através de muito aprendizado e trocas com especialistas. em um ambiente colaborativo.'
                    ]
                }
            ],
            rel:'inscrições encerradas!',
            link:'https://b2w.gupy.io/job/eyJqb2JJZCI6MTQ2MjE1NSwic291cmNlIjoiZ3VweV9wdWJsaWNfcGFnZSJ9?jobBoardSource=gupy_public_page',
            details:'conheça a trilha de dados' 
        },
    ]

    
    return (
        <Container {...props}>
                <div className={'careerTrails-container'}>
                    
                    <div className={'careerTrails-header'} >
                        <h1> Quer escolher a sua trilha? Aqui quem decide é você! </h1>
                    </div>
                    
                    <div className={'careerTrails-content'}>
                        {careerTrails && careerTrails.map((careerTrails) => (

                            <div key={careerTrails.title} className='box-limited'>

                                <div className={`careerTrail-grid`}>
                                    
                                    <div className={`careerTrail-box`}>

                                        <div className={'careerTrail-card-header'}>
                                            <div className={'careerTrail-header-label'}>{careerTrails.title}</div>
                                            <div className={'careerTrail-header-icon'}></div>
                                        </div>
                                        
                                        <div className={'careerTrail-card-content'}>
                                            <ul>
                                                {careerTrails.subtitle}
                                            </ul>
                                        </div>

                                        <div className={'careerTrail-card-content'}>
                                            <ul>
                                                {careerTrails.items.map((item, key) => (
                                                    <li key={key}>{item}</li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className={'details'} onClick={trailBoxClick.bind(this, careerTrails.class)}>
                                            {careerTrails.details}
                                        </div>

                                        <div className={`trailCard-box ${boxStateTrail[careerTrails.class] ? '--Open' : '--Close'}`}>
                                            
                                            <TrailCards key={careerTrails.class} value={careerTrails.trailsCard}/>
                                        </div>
                                        
                                        <div className={'careerTrail-card-bbutton'}>
                                            <a /*href={careerTrails.link}*/ target={'_blank'} rel={'noreferrer'}>{careerTrails.rel}</a>
                                        </div>

                                    </div>

                                </div>

                                <div className={`careerTrail-box-limited`}>  

                                    <h1 className={careerTrails.class}> OU </h1>
                                    
                                </div> 

                            </div>

                        ))}
                    
                    </div>

                </div>
            
        </Container>
    )
}