import styled from 'styled-components'

export const Container = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: ${props => props.theme.spacing.md} 0px;
    color: ${props => props.theme.color.neutral100};
    
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        z-index: 1;    
    }

    .label {
        font-size: 20px;
        margin-bottom: ${props => props.theme.spacing.xs};
    }

    .input {
        flex: 1;
        display: flex;
    }

    .input input {
        flex:1;
        font-family: 'Roboto', sans-serif;
        font-size: ${props => props.theme.font.sm};
        min-width: 440px;
        padding: 15px ${props => props.theme.spacing.md};
        border: none;
        border-radius: 0px;
        border-top-left-radius: ${props => props.theme.round.md};
        border-bottom-left-radius: ${props => props.theme.round.md};
        transition: all .3s;
    }
    
    @media screen and (max-width: 535px) {
        .input input {
            min-width: 240px;
        }
    }

    .input input::placeholder {
        color: ${props => props.theme.color.neutral700};
    }

    .input button {
        color: ${props => props.theme.color.neutral100};
        background-color: ${props => props.theme.color.orange500};
        border-radius: 0px;
        border-top-right-radius: ${props => props.theme.round.md};
        border-bottom-right-radius: ${props => props.theme.round.md};
    }

    .input button svg {
        width: 30px;
        height: 25px;
    }

`