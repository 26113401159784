import styled from 'styled-components'

import mais from './assets/images/mais.svg'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 352px;
    margin: ${props => props.theme.spacing.sm} 0;
    border-radius: ${props => props.theme.round.md};
    border: ${props => props.theme.line.sm} solid ${props => props.color};
    overflow: hidden;
    
    @media(max-resolution:100dpi) {
        border-width: ${props => props.theme.line.xs};
    }
    
    .opportunityCard-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: ${props => props.theme.color.neutral100};
        background-color: ${props => props.color};
        padding: ${props => props.theme.spacing.sm};
        padding-bottom: ${props => props.theme.spacing.lg};
    }
    
    .opportunityCard-headerTag {
        align-self: flex-end;
        color: ${props => props.theme.color.neutral800};
        padding: ${props => props.theme.spacing.xxs} ${props => props.theme.spacing.sm};
        margin-bottom: ${props => props.theme.spacing.sm};
        border-radius: ${props => props.theme.round.sm};
        background-color: ${props => props.theme.color.neutral100};
    }
    
    .opportunityCard-headerTitle {
        display: flex;
        flex-direction: column;
    }
    
    .opportunityCard-headerTitle span {
        font-family: 'Roboto-Light', sans-serif;;
        font-size: 24px;
    }
    .opportunityCard-headerTitle span:nth-child(2) {
        font-family: 'Roboto-Black';
        font-size: ${props => props.theme.font.xl};
        margin-top: ${props => props.theme.spacing.sm};
        line-height: 34px;
    }
    
    .opportunityCard-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: ${props => props.theme.spacing.sm};
        padding-bottom: ${props => props.theme.spacing.xs}
    }
    
    .opportunityCard-description {
        margin-top: ${props => props.theme.spacing.md};
    }
    
    @media only screen and (max-width: 568px) {
        .opportunityCard-description {
            display: none;
        }
    }

    .opportunityCard-content ul {
        display: flex;
        flex-direction: column;
        margin-top: ${props => props.theme.spacing.xl};
    }
    
    @media only screen and (max-width: 568px) {
        .opportunityCard-content ul {
            margin-top: 0;
        }
    }

    .opportunityCard-content a {
        border: none;
    }

    .opportunityCard-content li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        color: ${props => props.theme.color.red900};
        border-top: ${props => props.theme.line.sm} solid ${props => props.theme.color.orange500};
        padding: ${props => props.theme.spacing.sm} 0;
    }
    .opportunityCard-content li::after {
        content: "";
        width: 16px;
        height: 16px;
        background: url(${mais}) center center no-repeat;
    }

    @media only screen and (max-width: 568px) {
        .opportunityCard-content a:nth-child(1) li {
            border: none;
        }
    }
   
    .opportunityCard-seeMore a {
        display: block;
        text-align: center;
        border: none;
        color: ${props => props.theme.color.neutral100};
        background-color: ${props => props.theme.color.orange500};
        padding: ${props => props.theme.spacing.sm} 0;
    }
    
    `