import React from 'react'

import { Button } from '@material-ui/core'

import { Container } from './style'

import { Trails } from './components/Trails'
import { Prerequisites } from './components/Prerequisites'
import { Benefits } from './components/Benefits'

import americanas from './assets/images/americanas.svg'
import shoptime from './assets/images/shoptime.svg'
import submarino from './assets/images/submarino.svg'

export const EstagioTech = (props) => {

    const brands = [
        { name: 'americanas', logo: americanas, description: 'americanas', link: 'https://www.americanas.com.br/' },
        { name: 'shoptime', logo: shoptime, description: 'shoptime', link: 'https://www.shoptime.com.br/' },
        { name: 'submarino', logo: submarino, description: 'submarino', link: 'https://www.submarino.com.br/' },
    ]

    function toscroll(){
        const divElement = document.getElementById('trilhas')
        divElement.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <Container>

            <div className={'banner'}>

                <div className={'banner-slogan'} role={'img'} alt={'Duas mãos digitando em um teclado de notebook e fundo com pixels e caracteres usados em códigos de programação.'}>

                    <div className={'banner-title'}>

                        <div className={'banner-slogan-title'} role={'img'} alt={'Estágio tech'}></div>
                        
                        <div className={'banner-slogan-Sub-Title'} role={'img'} alt={'Inscrições abertas'}></div>
                    
                    </div>
                    
                    <div className={'rectangle_mulher'} role={'img'} alt={'Mulher de cabelo curto, pele de cor branca e tatuagem no antebraço usando fones de ouvido grandes e com o queixo apoiado sobre a mão.'} ></div>

                    <div className={'rectangle_homem'} role={'img'} alt={'Homem de óculos e pele de cor marrom olhando para o tablet que está segurando com as duas mãos.'} ></div>

                </div>
            
            </div>

            <div className={'container-box'}>
                <div className={'wrapper'}>

                    <div className={'header-line-row'}>

                        <div className={'header-line-slogan'}>
                            <div className={'slogan'} role={'img'} alt={'Acelere a sua carreira com o estágio tech'}> </div>
                        </div>

                        <div className={'header-line-button'}>
                            <Button onClick={toscroll}className={'header-button invisible'} size="large">inscreva-se</Button>
                        </div>
                        
                    </div>
                </div>
                
            </div>

            <div className={'body-description'}>

                <div className={'wrapper'}>
                    <h1 className={'body-decription-label'}>
                        Se você é apaixonado por inovação, colaboração e deseja trabalhar com muita tecnologia,
                        venha fazer parte da americanas s.a.!
                    </h1> 

                    
                    <div className={'body-description-content'}>

                        <div className={'body-decription-col'}>

                            <div className={'body-decription-row label-description'}>
                                    Nascemos da união das mais de 1700 Lojas Americanas com a B2W Digital, dona das marcas mais queridas da internet:
                            </div>

                            <div className={'body-decription-row label-description'}>

                                <div className="logo_brands">
                                    {brands.map((brand) => (
                                        <div key={brand.name} className={`item ${brand.name}`}>
                                            <a href={brand.link} target={'_blank'} rel={'noreferrer'}><img src={brand.logo} alt={brand.description} /></a>
                                        </div>
                                    ))}
                                </div>

                            </div>

                            <div className={'body-decription-row label-description'}>
                                Nosso propósito é somar o que o mundo tem de bom para melhorar a vida das pessoas!
                            </div>

                        </div>

                        <div className={'body-decription-col'}>

                            <div className={'body-decription-row label-description'}>
                                O Estágio Tech é pra quem quer acelerar a sua carreira! Em apenas 6 meses, você vai aprender na prática tudo o que precisa para se tornar um desenvolvedor ou um analista de dados júnior.
                            </div>
                            
                            <div className={'body-decription-row label-description'}>
                                E como fazemos isso? Com a colaboração do nosso time! Nossos Desenvolvedores, Engenheiros, Especialistas de TI, Líderes e time de Gente e Gestão preparam todo o conteúdo de aula com mais de 125 horas e atuam com instrutores e mentores dos jovens durante todo o programa.
                            </div>
                                
                            <div className={'body-decription-row label-description'}>

                                <div className={'label-description-col'}>
                                    O programa terá duração de 6 meses e, ao final, a efetivação só depende do seu desenvolvimento!
                                </div> 
                                
                            </div>

                        </div>
                        
                    </div>

                </div>
            
            </div>

            <div className={'container-box'}>

                <Trails id={'trilhas'}/>               
            
            </div>
            

            <div className={'box-informations'}>
                                    
                <div className={'box-decription-prerequisites'}>
                    
                    <Prerequisites id={'pre-requisitos'}/>

                </div>

                <div className={'box-decription-benefits'}>

                    <Benefits id={'Beneficios'}/>

                </div>
                
            </div>


        </Container>
    )
}