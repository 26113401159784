import styled from 'styled-components'

import rectangleHomem from './assets/rectangle_homem.png';
import rectangleMulher from './assets/rectangle_mulher.png';
import logoTrainee from './assets/logo-estagio-em-loja.svg'
import hashtag from './assets/hashtag.svg';
import background from './assets/banner-estagio-em-loja.png';

export const Container = styled.div `

    width: 100%;
    transition: all .3s;
    
    h1 {
        margin: 0;
    }

    .text-bold{
        color: ${props => props.theme.color.red550}; 
    }

    .banner {
        background: url(${background}) no-repeat ${props => props.theme.color.orange400};
        background-size: 50%;
        background-position-x: center;
        background-position-y: center;
        height: 399px;
    }

    .banner-slogan {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        height: inherit;
        background-size: 100% 100%;
        flex-wrap: wrap;
        max-width: 1180;
    }

    .banner-title {
        background-color: ${props => props.theme.color.neutral100};
        width: -webkit-fill-available;
        position: absolute;
        left: 0;
        top:0;
        margin-top: 76px;
        align-content: stretch;
        justify-content: space-between;
    }

    .banner-title-content {
        width: -webkit-fill-available;
        height: inherit;
        z-index: 1;
        position: absolute;
        margin-top: 100px;
    }

    .banner-slogan-footer {
        background-color: #f80032;
        bottom: 0;
        height: 70px;
        width: -webkit-fill-available;
        margin-top: auto;
    }

    .banner-slogan-title {
        background: url(${logoTrainee}) no-repeat left ${props => props.theme.color.neutral100};
        height: 76px;
        background-size: calc(20%);
        width: calc(100%);
    }

    .banner-slogan-content {
        width: calc(100%);
        height: 289px;
        width: calc(100%);
        display: flex;
        margin-top: 110px;
        background-position-x: center;
        background-position-y: center;
    }

    .rectangle-homem{
        background: url(${rectangleHomem}) no-repeat center;
        background-size: contain;
        height: inherit;
        width: 50%;
        z-index: 1;
        position: absolute;
        right: 0;
    }

    .rectangle-mulher{
        background: url(${rectangleMulher}) no-repeat center;
        background-size: contain;
        height: inherit;
        width: 50%;
        z-index: 1;
        position: absolute;
        left: 0;
    }

    .hashtag-info{
        background: url(${hashtag}) no-repeat center;
        background-size: 20%;
        position: absolute;
        height: inherit;
        width: 100%;
        z-index: 2;
    }

    .container-box{
        background: ${props => props.theme.color.red900};
        padding: 0 20px;
        z-index: 2;
        position: relative;
    }

    .header-line-row{
        display: flex;
    }

    .header-line-slogan{
        min-height: 150px;
        width: 100%;
    }

    .slogan{
        height: calc(100%);
        align-items: center;
        display: flex;
    }

    .text-white{
        color: ${props => props.theme.color.neutral100};
    }

    .header-line-button, .inscrição-line-button{
        margin: auto 0 auto auto;
    }

    .without-button{
        display: none;
    }

    .header-button{
        background-color: #FF9800;
        border-radius: 8px;
        float: right;
        color: #ffffff;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        min-width: 240px;
    }

    .inscrição-line-button .header-button {
        height: 35px;
        border-radius: 2px;
    }

    .header-button:hover{
        background-color: ${props => props.theme.color.orange500};
        a {
            border-color: ${props => props.theme.color.orange500};
        }
    }

    .body-description{
        background-color: ${props => props.theme.color.neutral100};
        height:auto;
        padding-bottom: 20px;
    }

    .body-description-content{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        -webkit-text-size-adjust: 16px;
        margin: 10px 20px;
    }

    .body-description-label{
        font-size: 32px;
        color: ${props => props.theme.color.red550};
        padding: 20px;
        line-height: 42px;
    }

    .body-decription-raw{
        font-size: 32px;
        color: ${props => props.theme.color.red550};
        margin: 50px 20px 10px;
    }

    .body-description-col{
        width: 500px;
    }

    .label-description-col{
        font-style: normal;
        color: ${props => props.theme.color.red550};
        font-weight: bold;
        line-height: 24px;
        padding-bottom: 20px;
    }

    .label-description{
        padding: 0 0 2% 4%;
    }

    .list-program ul{
        font-size: 16px;
        line-height: 27px;
    }

    .list-program ul li{
        display: flex;
        align-items: flex-start;
        margin-bottom: ${props => props.theme.spacing.sm};
    }

    .list-program ul li::before {
        content: "";
        min-width: 4px;
        min-height: 4px;
        margin-right: ${props => props.theme.spacing.md};
        margin-top: ${props => props.theme.spacing.xs};
        border: 2px solid #ff9800;

        border-radius: ${props => props.theme.round.sm};
    }

    .hashtag-color{
        color: ${props => props.theme.color.red550};
    }

    .bg-brands{
        background: transparent;
    }

    .logo_brands{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        position: relative;
        max-width: 1180px;
        margin: 0px auto;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        padding: 10px;
        justify-content: space-around;
    }

    .logo_brands a {
        border-bottom: none;
    }

    .box-informations { 
        background-color: ${props => props.theme.color.neutral200};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 30px;
        padding: 10px 20px;
    }

    .informations-items {
        max-width: 1180px;
        display: flex;
        flex-direction: row;
        margin: 0 10px;
        justify-content: space-around;
        min-height: 50px;
    }

    .banner span {
        display: block;
        font-weight: bold;
        margin-left: 20px;
        max-width: 280px;
    }

    .box-benefits {
        background-color: ${props => props.theme.color.red800};
        padding: 10px 20px;
    }

    @media only screen and  (min-width: 2000px) {

        .banner {
            background: url(${background}) no-repeat ${props => props.theme.color.orange400};
            background-size: 50%;
            background-position-x: center;
            background-position-y: center;
            height: 400px;
        }
    }

    @media only screen and (min-width: 1440px){

        .banner-slogan {
            display: flex;
            align-items: center;
            justify-content: center;
            height: inherit;
        }

        .header-button{
            min-width: 240px;
        }

        .box-description-prerequisites, .box-description-cards-timeline {
            width: 50%;
        }
    }

    @media only screen and (min-width: 1024px){

        .header-button{
            min-width: 240px;
        }

        .informations-items {
            margin: 0 10px;
        }
    }
    
    @media only screen and (max-width: 1440px){

        .inscricoes-aberta{
            padding-left: 7.5%;
        }

    }

    @media screen and (max-width: 1280px){

        .banner{
            height: 285px;
        }

        .banner-slogan-title{
            margin: 1px 20px;
        }
    }

    @media screen and (max-width: 950px){
        
        .banner-slogan{
            width: 100%;
            background-size: contain;
            height: inherit;
            background-position: bottom;
        }

        .rectangle_mulher{
            position: absolute;
            background-size: auto;
        }
    }
    
    @media screen and (max-width: 830px){

        .banner{
            background: url(${background}) no-repeat left ${props => props.theme.color.orange400};
            background-size: contain;
            background-position-x: calc(-60px);
            background-position-y: center;
        }

        .rectangle-homem {
            display: none;
        }

        .rectangle-mulher {
            width: 80%;
            right: 0;
            background-size: 69%;
            left: auto;
            right: 0;
            background-position-x: right;
        }

        .hashtag-info {
            width: 50%;
            left: 0;
            background-size: 65%;
            background-position-y: center;
        }

        .banner-slogan-title{
            background-position-x: left ;
            background-size: 30%;
            margin: 5px 20px;
        }

        .slogan-text{
            padding-right: 20px;

            h1{
                font-size: 30px;
                line-height: 29px;
            }
        }

        .box-informations, .informations-items {
            display: block;
            padding: 0 10px;
        }

        .body-description-content{
            flex-direction: column;
        }

        .body-description-col{
            width: 100%;
            padding-top: 20px;
        }

        .logo_brands{
            display: flex;
            flex-wrap: wrap;
            margin: 20px;

            .item{

                img{
                    width: calc(90%);
                    height: 70px;
                }
            }  
        }

        .body-description-label{
            font-style: normal;
            font-weight: normal;
            line-height: 29px;
            margin: 0 10px;
            padding-left: 0;
        }

        .label-description-col{
            font-size: 16px;
        }

        .body-description-content{
            padding: 30px 10px;
            margin-top: 20px;
        }

    }

    @media screen and (max-width: 768px) {

        .rectangle-mulher {
            background: url(${rectangleMulher}) no-repeat bottom;
            background-size: cover;
            width: 60%;
            background-position-x: inherit;
            background-position-y: center;
        }

        .inscricoes-aberta{
            padding-right: 8%;
            padding-bottom: 85px;
            padding-left: 15%;
        }

        .body-description-content{
            margin:0;
            padding: 10px;
            padding-bottom: 50px;
        }

        .logo_brands{
            .item{
                width: calc(50%);
                img{
                    width: calc(90%);
                    height: 70px;
                }
            }    
        }

        .inscrição-line-button{
            margin: 10px auto;
            margin-top: -15px;
            display: grid;
            justify-content: space-around;
        }

        .slogan{
           max-width: 100%;
           justify-content: space-around;
        }

        .slogan-text{
            padding: 10px;

            h1{
                font-size: 30px;
                line-height: 29px;
            }
        }

        .box-description-prerequisites{
            padding-left: 0;
        }
    }

    @media screen and (max-width: 530px) {

        .hashtag-info {
            background-size: 70%;
            width: 70%;
            height: 100px;
            margin-top: 10%;
            background-position-x: initial;
        }

        .header-line-row {
            flex-direction: column;
        }

        .banner-slogan-title {
            background: url(${logoTrainee}) no-repeat center ${props => props.theme.color.neutral100};
            height: 76px;
            background-size: calc(60%);
            width: calc(100%);
            margin: auto;
        }

        .banner-slogan-pre-Title, .banner-slogan-pros-Title {
            display: none;
        }

        .rectangle-mulher {
            width: 100%;
            background-size: contain;
            background-position-y: center;
            background-position-x: calc(112px);
        }

        .slogan{
           margin: 5px 0;
           text-align: center;
        }

        .header-line-slogan {
            min-height: 95px;
            width: 100%;
        }

        .header-button{
            height: 35px;
        }

        .header-line-button {
            margin: 10px auto;
        }

        .logo_brands{

            margin: 10px;
            .submarino{
                width: 80% ;
                height: 70px;
            }   
        }
    }

    @media only screen and (max-width: 375px){

        .rectangle-mulher {
            background-size: cover;
            background-position-x: 55px;
        }
        
    }

    @media only screen and (max-width: 320px){
        .rectangle-mulher {
            background-size: cover;
            background-position-x: inherit;
        }
    }

`

