import styled from 'styled-components'

import mais from './assets/images/mais.svg'
import menos from './assets/images/menos.svg'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    
    h1{
        color: ${props => props.theme.color.neutral100};
        font-size: 35px;
        margin: revert;
        margin-top: 7%;
    }

    .careerTrails-container {
        align-items: center;
        margin: ${props => props.theme.spacing.md} 0;
        display: flex;
        flex-direction: column;
        max-width: 1180px;
    }

    .careerTrails-header{
        width:calc(90%);
        h1{
            margin-top: 50px;
            margin-bottom: 30px;
            font-style: normal;
            font-weight: normal;
            font-size: 30px;
            line-height: 42px;
        }
    }

    .careerTrails-content{
        width: max-content;
        display: flex;
        width: calc(90%);
    }

    .careerTrail-grid {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        h1{
            margin: auto 50px;
            line-height: 24px;
            font-weight: 900;
        }
    }

    .careerTrail-box {
        padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.md};
        margin-bottom: ${props => props.theme.spacing.md};
        border-radius: ${props => props.theme.round.md};
        min-height: 413px;
        background: ${props => props.theme.color.neutral100};

    }

    .careerTrail-box-limited{
        max-width: 448px;
        min-height: 413px;
        display: contents;
    }

    .box-limited{
        display: contents;
    }

    .development-trail{
        margin: auto 30px;
        font-weight: bold;
        font-size: 48px;
        line-height: 42px;
    }

    .data-trail{
        display: none;
    }

    .careerTrail-card-header {
        user-select: none;
        display: flex;
        align-items: center;
        text-align: center;
        cursor: pointer;
        padding-bottom: ${props => props.theme.spacing.lg};
        padding-top: ${props => props.theme.spacing.md};
    }

    .careerTrail-header-label {
        flex: 1;
        color: ${props => props.theme.color.red900};
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 42px;
    }

    .careerTrail-header-icon {
        width: 16px;
        height: 16px;
        transition: all .6s;
    }
    .careerTrail-box.--isOpen .careerTrail-headerIcon {
        background: url(${menos}) center center no-repeat;
    }
    .careerTrail-box.--isClose .careerTrail-headerIcon {
        background: url(${mais}) center center no-repeat;
        transform: rotateZ(180deg);
    }

    .careerTrail-card-content {
        pointer-events: none;
        margin-top: ${props => props.theme.spacing.md};
        transition: max-height .6s, opacity .3s;
        max-height: 888px;
        margin: 0 30px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
    }
    
    .careerTrail-card-content ul li {
        display: flex;
        align-items: flex-start;
        margin-top: ${props => props.theme.spacing.md};
    }
    
    .careerTrail-card-content ul li::before {
        content: "";
        min-width: 4px;
        min-height: 4px;
        margin-right: ${props => props.theme.spacing.md};
        margin-top: ${props => props.theme.spacing.xs};
        border: ${props => props.theme.line.md} solid ${props => props.theme.color.orange500};
        border-radius: ${props => props.theme.round.sm};
    }

    .careerTrail-card-bbutton a {
        display: block;
        text-align: center;
        border: none;
        color: ${props => props.theme.color.neutral100};
        background-color: ${props => props.theme.color.orange500};
        padding: ${props => props.theme.spacing.sm} 0;
        border-radius: ${props => props.theme.round.md};
        border: ${props => props.theme.line.sm} solid ${props => props.theme.color.orange500};
        width: 70%;
        margin: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        padding-left: 10%;
        padding-right: 10%
    }

    @media only screen and (max-width: 768px) {
        .careerTrail-box {
            width: 100%;
            padding: 20px 0;
        }

        .careerTrails-content{
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            width: 100%;
            margin: 0 10px;
        }

        .careerTrail-grid{
            display: flex;
            justify-content: space-around;
            flex-direction: column
        }

        .careerTrail-box-limited .development-trail{
            margin: 30px auto;
        }

        .careerTrails-header{
            width: 100%;

            h1{
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 29px;
                padding: 0 2%;
            }
        }

        careerTrail-card-bbutton a {
            padding-left: 20%;
            padding-right: 20%
            width: 90%;
        }
    }

    .details{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-decoration-line: underline;
        margin: 50px 30%;
        color: #BB0F33;
        cursor: pointer;
    }

    .trailCard-box {
        width: 100%;
    }

    .trailCard-box.--Open  {
        display: flex;
    }

    .trailCard-box.--Close {
        display: none;
    }


    @media only screen and (min-width: 769px) and (max-width: 2560px){
        .careerTrail-box {
            max-width: 600px;
        }

    }

    @media only screen and (max-width: 321px){

        .careerTrail-card-bbutton a {
            width: 90%;
            white-space: pre-wrap;
        }

        .details{
            margin: 50px 20%;
        }

        .careerTrail-card-content{
            margin: 0 10px;
            font-size: 16px;
            line-height: 16px;
        }

        .careerTrail-header-label {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 16px;
            text-align: center;
        }

    }

`