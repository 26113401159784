import styled from 'styled-components'

export const Container = styled.div`
    button {
        font-size: 12px;
        color: ${props => props.theme.text.accentVariant};
        background-image: ${props => props.variant === 'primaryVariant' ? `linear-gradient(${props.theme.background.accentVariant5}, ${props.theme.background.accentVariant6})` : `linear-gradient(${props.theme.background.accent}, ${props.theme.background.accentVariant2})`};
        border-radius: 30px;
        padding: 10px 20px;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    }
`