import React from 'react'

import OwlCarousel from 'react-owl-carousel'

import { Container } from './style'

import americanas from './assets/images/americanas.svg'
import shoptime from './assets/images/shoptime.svg'
import submarino from './assets/images/submarino.svg'
import ame from './assets/images/ame.svg'
import vem from './assets/images/vem.svg'
import grupounico from './assets/images/grupounico.svg'
import hortifruti from './assets/images/hortifruti.svg'


export const Brand = () => {

    const slider = React.createRef()

    const brands = [
        { name: 'americanas', logo: americanas, description: 'americanas', link: 'https://www.americanas.com.br/' },
        { name: 'shoptime', logo: shoptime, description: 'shoptime', link: 'https://www.shoptime.com.br/' },
        { name: 'submarino', logo: submarino, description: 'submarino', link: 'https://www.submarino.com.br/' },
        { name: 'ame', logo: ame, description: 'ame', link: 'https://www.amedigital.com/' },
        { name: 'vem', logo: vem, description: 'vem conveniência ', link: 'https://www.vibraenergia.com.br/vem-conveniencia' },
        { name: 'grupounico', logo: grupounico, description: 'grupo unico', link: 'https://www.linkedin.com/company/somosgrupounico/' },
        { name: 'hortifruti', logo: hortifruti, description: 'sou barato', link: 'https://hortifruti.com.br/' },
    ]

    return (
        <Container>
            <div className={'wrapper'}>

                <div className={'button prev'} onClick={() => slider.current.prev()}></div>
                <div className={'button next'} onClick={() => slider.current.next()}></div>

                <OwlCarousel
                    ref={slider}
                    dots={false}
                    loop
                    responsive={{
                        0: {
                            items: 1,
                        },
                        872: {
                            items: 3
                        },
                        1144: {
                            items: 4
                        }
                    }}>
                    {brands.map((brand) => (
                        <div key={brand.name} className={`item ${brand.name}`}>
                            <a href={brand.link} target={'_blank'} rel={'noreferrer'}><img className={`${brand.name}`} src={brand.logo} alt={brand.description} /></a>
                        </div>
                    ))}
                </OwlCarousel>

            </div>
        </Container>
    )
}