import styled from 'styled-components'

import background from './assets/images/background.jpg'
import background2x from './assets/images/background2x.jpg'

import slogan from './assets/images/slogan.svg'
import rectangle_mulher from './assets/images/rectangle_mulher.png'
import rectangle_homem from './assets/images/rectangle_homem.png'
import splash from './assets/images/splash_encerrada.svg'
import acelereSuaCarreira from './assets/images/acelereSuaCarreira.svg'

export const Container = styled.div `

    color: #555960;
    width: 100%;
    transition: all .3s;

    a {
        border-bottom: ${props => props.theme.line.sm} solid transparent;
    }

    .banner {
        background: linear-gradient(90deg, #F775A5 0%, #F80032 100%);
        height: 399px;
    }

    .banner-slogan {
        display: flex;
        align-items: center;
        justify-content: center;
        height: inherit;
        background: url(${background2x}) no-repeat center;
        flex-direction: column;
        height: inherit;
        background-size: 100% 99%;
    }

    .banner-title {
        width: calc(75%);
        z-index: 1;
        position: absolute;
    }
    
    .banner-slogan-title {
        background: url(${slogan}) no-repeat center;
        height: 85px;
        background-size: calc(80%);
        max-width: 670px;
    }

    .banner-slogan-Sub-Title {
        background: url(${splash}) no-repeat center;
        height: 90px;
        background-size: calc(65%);
        max-width: 670px;
    }

    .rectangle_mulher{
        background: url(${rectangle_mulher}) no-repeat right;
        width: 100%;
        height: 405px;
        right: 0;
        top: 0;
        background-size: contain;
    }

    .rectangle_homem{
        background: url(${rectangle_homem}) no-repeat left;
        height: inherit;
        width: 100%;
        bottom: 0;
        background-size: contain;
    }

    .container-box{
        background: linear-gradient(270.69deg, #F775A5 0%, #F80032 100%);
        padding: 0 20px;
    }

    .header-line-row{
        display: flex;
    }

    .header-line-slogan{
        min-height: 150px;
        width: 50%;
    }

    .slogan{
        background: url(${acelereSuaCarreira}) no-repeat center;
        background-size: 100%;
        max-width: 390px;
        height: calc(100%);
    }
    
    .header-line-button{
        margin: auto 0 auto auto;
    }

    .header-button{
        background-color: #FF9800;
        border-radius: 8px;
        float: right;
        color: #ffffff;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        min-width: 240px;
    }

    .header-button:hover{
        background-color: #ff8723;
    }

    .body-description{
        background: linear-gradient(259.85deg, rgba(247, 117, 165, 0.1) 0%,
         rgba(247, 117, 165, 0.3) 99.13%);
        height:auto;
        width: 100%;
    }

    .body-description-content{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
        padding-left: 40px;
    }

    .body-decription-label{
        font-size: 30px;
        color: #BB0F33;
        margin: 0 30px;
        padding: 20px;
        padding-left: 30px;
        line-height: 42px;
        margin-top: 50px;
    }

    .body-decription-row{
        margin: auto;
        padding: 0 6.5%;
        padding-right: 0px;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    .body-decription-col{
        width: calc(95%);
    }

    .body-decription-row{
        margin-bottom: 5%;
        font-size: 16px;
    }

    .label-description-col{
        font-style: normal;
        color: #F80032;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    }

    .label-description{
        padding: 0 0 2% 4%;
    }

    .logo_brands{
        display:flex; 
        flex-wrap: wrap; 
        align-items: center;
        justify-content: space-around;
    }

    .box-informations{
        min-height:506px
        width: 100%;
        display: -webkit-box;
    }

    .box-decription-prerequisites{
        background: #FF9800;
        width: calc(50%);
    }

    .box-decription-benefits{
        background: #F80032;
        width: calc(50%);
    }

    .banner span {
        display: block;
        font-weight: bold;
        margin-left: 20px;
        max-width: 280px;
    }

    @media screen and (max-width: 950px){
        .banner-slogan{
            background: url(${background}) no-repeat right;
            width: 100%;
            background-size: cover;
            height: inherit;
        }

        .rectangle_homem{
            display: none;
        }

        .rectangle_mulher{
            position: absolute;
            background-size: auto;
        }

        .banner-slogan-title{
            background-size: calc(90%);
        }

        .banner-slogan-Sub-Title{
            background-size: calc(80%);
        }

        .slogan{
            background-size: contain;
            height: 90%;
            background-position: center;
        }
    }


    @media only screen and (min-width: 1440px) and (max-width: 2560px){

        .banner-slogan {
            display: flex;
            align-items: center;
            justify-content: center;
            height: inherit;
            background: url(${background}) no-repeat center;
            background-size: 100% 99%;
        }

        .header-button{
            min-width: 240px;
        }

    }

    @media only screen and (min-width: 1024px) and (max-width: 2560px){
        .body-decription-col {
            max-width: 448px;
        }

        .header-button{
            min-width: 240px;
        }
    }


    @media only screen and (min-width: 600px) and (max-width: 1024px){

        .banner-sloganTitle {
            height: 90px;
        }

        .header-button{
            min-width: 240px;
        }
    }
    

    @media screen and (max-width: 768px){
        .header-line-row{
            padding: 0 20px;
            height: 200px;
        }

        .body-description{
            padding: 0 10px;
        }

        .box-decription-prerequisites{
            padding: 0 10px;
        }

        .box-decription-benefits{
            padding: 0 10px;
        }

        .box-informations{
            display: flex;
            flex-direction: column;
        }

        .box-decription-benefits{
            width: calc(100%);
        }

        .box-decription-prerequisites{
            width: calc(100%);
            display: flex;
            justify-content: flex-start;
        }

        .body-description-content{
            flex-direction: column;
            margin: 0px;
            padding: 0px;
        }

        .body-decription-col{
            width: calc(100%);
        }

        .logo_brands{
            .item{
                width: calc(50%);
                display: flex;
                flex-direction: row;
                justify-content: space-around;
    
                img{
                    width: calc(100%);
                }
            }
        }

        .body-decription-label{
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            margin: 0 10px;
            padding-left: 0;
            padding: 5% 2% 2%;
            margin-top: 50px;
        }

        .label-description-col{
            font-size: 16px;
        }

    }

    @media screen and (max-width: 540px) {

        .body-decription-row{
            margin-bottom: 5px;
        }

        .logo_brands{
            .item{

                img{
                    width: calc(90%);
                    height: 70px;
                }
            }    
        } 

        .header-line-row{
            display: flex;
            flex-direction: column;
            padding: 0;
        }

        .header-line-slogan{
            width: 100%;
            height: 90px;
            padding: initial;
        }

        .header-line-button{
            margin: auto;
            margin-top: -25px;
        }

        .banner-title{
            width: 90%;
        }

        .slogan {
            background-size: 80%;
            background-position: center;
        }

        .banner-slogan-title {
            background-size: 95%;
        }

        .banner-slogan-Sub-Title {
            background-size: 95%;
        }

    }

    @media only screen and (max-width: 320px){

        .banner-title{
            width: 95%;
        }
    }


`