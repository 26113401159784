import React from 'react';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { scrollToElement } from 'utils';

import { Container } from './style';

import { ReactComponent as Linkedin } from 'assets/images/social/linkedin.svg';
import { ReactComponent as Instagram } from 'assets/images/social/instagram.svg';
import { ReactComponent as Glassdoor } from 'assets/images/social/glassdoor.svg';
import { ReactComponent as Spotify } from 'assets/images/social/spotify.svg';

export const Header = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  React.useEffect(() => {
    if (props.location.pathname === '/' && props.location.hash === '') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [props.location]);

  const handleMobileMenuClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Container>
      <div className={'wrapper'}>
        <div className={'header-container'}>
          <Link to={'/'} className={'header-logoLink'}>
            <div className={'header-logo'}></div>
          </Link>

          <div className={'header-menu'}>
            <ul>
              <li>
                <Link
                  to={'/'}
                  className={
                    props.location.pathname === '/' &&
                    props.location.hash === ''
                      ? '--active'
                      : ''
                  }
                >
                  home
                </Link>
              </li>
              <li>
                <HashLink
                  to={{ pathname: '/', hash: 'sobre' }}
                  scroll={scrollToElement.bind(this)}
                  className={
                    props.location.pathname === '/' &&
                    props.location.hash === '#sobre'
                      ? '--active'
                      : ''
                  }
                >
                  sobre
                </HashLink>
              </li>
              <li>
                <HashLink
                  to={{ pathname: '/', hash: 'programas' }}
                  scroll={scrollToElement.bind(this)}
                  className={
                    props.location.pathname === '/' &&
                    props.location.hash === '#programas'
                      ? '--active'
                      : ''
                  }
                >
                  programas
                </HashLink>
              </li>
              <li>
                <Link
                  to={'/vagas'}
                  className={
                    props.location.pathname.includes('/vagas') ? '--active' : ''
                  }
                >
                  vagas
                </Link>
              </li>
            </ul>
          </div>

          <div className={'social'}>
            <a
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/americanas-sa/"
              target={'_blank'}
            >
              <div className={'socialIcon linkedin'}>
                <Linkedin />
              </div>
            </a>

            <a
              rel="noopener noreferrer"
              href="https://www.glassdoor.com.br/Visão-geral/Trabalhar-na-americanas-s-a-EI_IE10939.13,27.htm"
              target={'_blank'}
            >
              <div className={'socialIcon glassdoor'}>
                <Glassdoor />
              </div>
            </a>

            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/carreiras.americanas/"
              target={'_blank'}
            >
              <div className={'socialIcon instagram'}>
                <Instagram />
              </div>
            </a>

            <a
              rel="noopener noreferrer"
              href="https://open.spotify.com/show/5KkY7r5w0qC1y3HZLozGnV"
              target={'_blank'}
            >
              <div className={'socialIcon spotify'}>
                <Spotify />
              </div>
            </a>
          </div>

          <div className={'mobile-menu'}>
            <div
              className={`mobile-menuTrigger ${
                isMobileMenuOpen ? '--isOpen' : '--isClose'
              }`}
              onClick={handleMobileMenuClick}
            ></div>

            <div
              className={`mobile-menuContent ${
                isMobileMenuOpen ? '--isOpen' : '--isClose'
              }`}
            >
              <ul>
                <li onClick={handleMobileMenuClick}>
                  <Link
                    to={'/'}
                    className={
                      props.location.pathname === '/' &&
                      props.location.hash === ''
                        ? '--active'
                        : ''
                    }
                  >
                    home
                  </Link>
                </li>
                <li onClick={handleMobileMenuClick}>
                  <HashLink
                    to={{ pathname: '/', hash: 'sobre' }}
                    scroll={scrollToElement.bind(this)}
                    className={
                      props.location.pathname === '/' &&
                      props.location.hash === '#sobre'
                        ? '--active'
                        : ''
                    }
                  >
                    sobre
                  </HashLink>
                </li>
                <li onClick={handleMobileMenuClick}>
                  <HashLink
                    to={{ pathname: '/', hash: 'programas' }}
                    scroll={scrollToElement.bind(this)}
                    className={
                      props.location.pathname === '/' &&
                      props.location.hash === '#programas'
                        ? '--active'
                        : ''
                    }
                  >
                    programas
                  </HashLink>
                </li>
                <li onClick={handleMobileMenuClick}>
                  <Link
                    to={'/vagas'}
                    className={
                      props.location.pathname === '/vagas' ? '--active' : ''
                    }
                  >
                    vagas
                  </Link>
                </li>
              </ul>

              <div className={'social'}>
                <a
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/americanas-sa/"
                  target={'_blank'}
                >
                  <div className={'socialIcon linkedin'}>
                    <Linkedin />
                  </div>
                </a>

                <a
                  rel="noopener noreferrer"
                  href="https://www.glassdoor.com.br/Visão-geral/Trabalhar-na-americanas-s-a-EI_IE10939.13,27.htm"
                  target={'_blank'}
                >
                  <div className={'socialIcon glassdoor'}>
                    <Glassdoor />
                  </div>
                </a>

                <a
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/carreiras.americanas/"
                  target={'_blank'}
                >
                  <div className={'socialIcon instagram'}>
                    <Instagram />
                  </div>
                </a>

                <a
                  rel="noopener noreferrer"
                  href="https://open.spotify.com/show/5KkY7r5w0qC1y3HZLozGnV"
                  target={'_blank'}
                >
                  <div className={'socialIcon spotify'}>
                    <Spotify />
                  </div>
                </a>
              </div>

              <div className={'selo'}></div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
