import styled from 'styled-components'

import igual from './assets/images/igual.svg'

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${props => props.theme.spacing.xl} 0;
    
    .values-container {
        position: relative;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        margin: ${props => props.theme.spacing.md} 0;
    }
    
    .values-container .shape1 {
        position: absolute;
        bottom: 50%;
        left: -80px;
        width: 100px;
        height: 30px;
        background-color: ${props => props.theme.color.orange900};
    }

    .values-container .shape2, .values-container .shape3 {
        position: absolute;
        bottom: 30%;
        right: -80px;
        width: 100px;
        height: 15px;
        border: ${props => props.theme.line.md} solid ${props => props.theme.color.orange500};
    }

    .values-container .shape3 {
        bottom: 50%; 
    }

    .values-container .shape4 {
        position: absolute;
        top: -50px;
        right: 20px;
        width: 50px;
        height: 60px;
    }

    .values-container .shape4 svg {
        width: 50px;
        height: 80px;
    }
    
    .values-container .shape4 svg .a {
        font-family: 'Roboto-Black';
        fill: ${props => props.theme.color.red500};
    }
    
    @media only screen and (max-width: 842px) {
        & .shape1, & .shape2, & .shape3, & .shape4 {
            display: none;
        }
    }

    .values-container ul {
        min-width: 320px;
        margin: 0 ${props => props.theme.spacing.md};
    }
    
    .values-container ul li {
        list-style-image: url(${igual});
        text-indent: 5px;
        margin-bottom: ${props => props.theme.spacing.xs};
    }

    @media only screen and (max-width: 840px) {
        .values-container ul {
            min-width: 80%;
            width: 80%;
            margin-left: 10%;
        }
    }

`