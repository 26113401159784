import React from 'react'

import { Button } from '@material-ui/core'

import { Container } from './style'

export const CardTimeline = (props) => {

    const [boxState, setBoxState] = React.useState({
        inscricao: false,
        testes: false,
        desafio: false,
        game: false,
        day: false,
        painel: false
    })

    const cards = [
        {
            name: 'Inscrição',
            content: `Nossa inscrição é feita pela Gupy. Para que possamos te 
                    conhecer melhor, pedimos que preencha um simples cadastro.  `
        },
        {
            name: 'Testes Gamificados',
            content: `Os testes são gamificados, assim você avalia seus 
                    conhecimentos mas se diverte ao mesmo tempo! `
        },
        {
            name: 'Desafio Decode',
            content: `O desafio será totalmente interativo, você vai desvendar 
                em grupo por um jogo online diversos enigmas da americanas s.a. 
                Vai ser incrível! ;) `
        },
        {
            name: 'Game Americanas s.a.',
            content: `Nessa fase, o time de Gente e Gestão vai te conhecer melhor. 
                    O desafio é grande, mas será uma experiência divertida 
                    em um ambiente descontraído.`
        },
        {
            name: 'Day Challenge',
            content: `Participar do Day Challenge significa uma imersão 
                    incrível no nosso negócio! Teremos cases práticos, que contarão 
                    com a participação das nossas lideranças.`
        },
        {
            name: 'Painel com a Diretoria',
            content: `O grande dia! Nessa última etapa você conhecerá e 
                    terá a chance de se apresentar para os CEOs 
                    e Diretoria da americanas s.a.! `
        },
    ]

    const handleHeaderClick = (boxName) => {
        setBoxState({
            inscricao: false,
            testes: false,
            desafio: false,
            game: false,
            day: false,
            painel: false,
            [boxName]: !boxState[boxName]
        })
    }

    return (
        <Container {...props}>

            <div className='header-card'>
                <div className='header-title-card'>
                    <h2 >processo seletivo <b>100% online</b></h2>
                </div>
            </div>
            
            <div className={'principles-container'}>

                {cards && cards.map((card) => (
                 
                    <div key={card.name} className='card'>

                        <p className='contador'></p>

                        <div key={card.name} className={`principle-box ${boxState[card.name] ? '--isOpen' : '--isClose'}`}>

                            <div className={'principle-header'} onClick={handleHeaderClick.bind(this, card.name)}>
                                <h5 className={'principle-headerLabel'}>{card.name}</h5>
                                <div className={'principle-headerIcon'}></div>
                            </div>

                            <div className={'principle-content'}>
                                {card.content}
                            </div>

                        </div>
                            
                    </div>
                ))}

                <div className='button-box'>
                    <div className={'header-line-button without-button'}>
                        <Button className={'header-button'} size="large">inscreva-se</Button>
                    </div>
                </div>

            </div>

        </Container>
    )
}