import React from 'react'

import { Container } from './style'

import { ReactComponent as Ampersand } from './assets/images/ampersand.svg'

export const Purpose = (props) => {

    return (
        <Container {...props}>

            <h2>nosso <b>propósito</b></h2>

            <div className={'shape9'} />
            <div className={'shape10'} />
            <div className={'shape11'} />
            <div className={'shape12'} />
            {/* <div className={'shape13'}><Ampersand /></div> */}

            <div className={'purpose-container'}>

                <div className={'shape1'} />
                <div className={'shape2'} />
                <div className={'shape3'} />
                <div className={'shape4'} />
                <div className={'shape5'} />
                <div className={'shape6'} />
                <div className={'shape7'} />
                <div className={'shape8'} />

                <div className={'purpose-text'}>
                    <span><b>Somar</b></span>
                    <span>o que o mundo tem de bom</span>
                    <span>para <b>melhorar</b></span>
                    <span><b>a vida das pessoas.</b></span>
                </div>

            </div>

        </Container>
    )
}